import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TreeNodeStyled } from "./styled";
import { observer, inject } from "mobx-react";
import TreesStore from "../../../../../stores/TreesStore";
import PcBook from "../PcBook/PcBook";
import PcCategories from "../PcCategories/PcCategories";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}

class TreeNode extends React.PureComponent<Props, any> {
  render() {
    const { treesStore } = this.props;
    const nodeToken = this.props.match.params?.id;
    console.log({nodeToken})
    treesStore!.setCurrentNodeToken(nodeToken);
    const treeName: any = this.props.match.url.split("/")[1];
    treesStore!.setCurrentTreeName(treeName);
    // console.log(this.props!.treesStore?.currentNode);
    return (
      <TreeNodeStyled>
        {!treesStore?.currentNodeIsCategory ? <PcBook /> : <PcCategories />}
      </TreeNodeStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(TreeNode)));
