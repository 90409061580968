import { Affix, Breadcrumb } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import UiStore from "../../stores/UiStore";
import "./Breadcrumbs.scss";

// type route = {
//   path: string;
//   text: string;
// };

interface Props extends RouteComponentProps<any> {
  uiStore?: UiStore;
}

class Breadcrumbs extends React.PureComponent<Props, any> {
  openTableContent = () => {
    window.scrollTo(0, 0);
    this.props.uiStore!.displayTableOfContent = true;
  };

  render() {
    const items = this.props.uiStore!.breadcrumbs;
    const isLastItem = (arr: any[], index: number) => index === arr.length - 1;
    return (
      <Affix offsetTop={0}>
        <div className="br-bottom br-gray2 padding20 textRight Bradcrumps b-white">
          {/* <Button
            className="toggleDisplayTableOfContent"
            onClick={() => this.openTableContent()}
          >
            <InsertRowLeftOutlined className="margin3 open_menu" />
            תוכן עניינים
          </Button> */}

          <Breadcrumb>
            {items.map((item: any, i: any) => (
              <Breadcrumb.Item key={i}>
                {isLastItem(items, i) ? (
                  item.text
                ) : (
                  <Link to={item.path}>{item.text}</Link>
                )}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      </Affix>
    );
  }
}
export default withRouter(inject("uiStore")(observer(Breadcrumbs)));
