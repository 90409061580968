import styled from "styled-components";

const BookContentStyled: any = styled.div`
  position: relative;
  .gal-enai-tooltip {
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background: #68abcc;
    color: white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    span {
      color: white !important;
    }
    &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #1d455f;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    }
  }

  .tooltip-small {
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background: #68abcc !important;
    color: white !important;
    width: max-content;
    top: -28px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .triangle-up {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #68abcc;
    bottom: -11px;
  }

  a {
    position: relative;
  }

  * {
    color: #1d455f !important;
    background-color: transparent !important;
    border: none !important;
    line-height: initial !important;
  }
  span {
    /* font-family: 'Open Sans', sans-serif !important; */
    font-family: "Open Sans Hebrew", serif !important;
    /* font-size:22px !important; */
  }

  h1 span,
  h1 {
    font-size: 50px !important;
    font-weight: 400;
  }
  h2 span,
  h2 {
    font-size: 34px !important;
  }
  h3 span,
  h3 {
    font-size: 30px !important;
  }
  h4 span,
  h4 {
    font-size: 22px !important;
  }
  .Miriam {
    font-family: MiriamLibre !important;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.7;
    text-align: justify;
    color: #1d455f;
    text-align: center;
  }
  .Quote span,
  .Quote {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-style: italic !important;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: justify;
    color: #1d455f;
  }
  p {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: justify;
    color: #1d455f;
  }
  p span {
    font-size: 22px !important;
  }
  h1 {
    font-size: 50px;
    line-height: 1.02;
    text-align: right;
    color: #1d455f;
    font-weight: 800;
  }
  h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.12;
    text-align: right;
    color: #1d455f;
  }
  h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    text-align: right;
    color: #1d455f;
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.64;
    text-align: right;
    color: #1d455f;
  }

  .a74 {
    text-align: center;
  }
  img {
    display: none;
  }
  .max-width {
    max-width: 800px;
    margin: 0 auto;
  }

  .FootnoteReference {
    font-size: 16px !important;
  }

  table {
    margin: 0 auto;
    p {
      text-align: center;
    }
  }
  .ltr {
    direction: ltr;
  }
`;

export { BookContentStyled };
