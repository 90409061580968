import { action, observable, makeObservable } from "mobx";
import Api from "../services/Api";
import BaseStore from "./BaseStore";
export default class SearchStore extends BaseStore {
  api: Api;

  totalResults: number = 0;
  currentPage: number = 1;
  pageSize: number = 50;

  displayNoResultsMessage: boolean = false;
  query: string = "";
  searchType: any = "";
  skipDuplicates: any = "";
  searchMethod: any = "";
  results: any = [];
  loading: boolean = false;
  searchArea: any = {
    books: true,
    subjects: true,
    audio: false,
    lessons: true,
  };

  constructor(api: Api) {
    super();

    makeObservable(this, {
      totalResults: observable,
      currentPage: observable,
      pageSize: observable,
      displayNoResultsMessage: observable,
      query: observable,
      searchType: observable,
      skipDuplicates: observable,
      searchMethod: observable,
      results: observable,
      loading: observable,
      searchArea: observable,
      cleanResults: action
    });

    this.api = api;
  }

  setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  cleanResults = () => {
    this.results = [];
    this.totalResults = 0;
  };

  search = async (searchParams: any) => {
    try {
      // debugger;
      this.loading = true;
      this.displayNoResultsMessage = false;
      // const { promise } = this.api.searchTextInBookAndLessons(
      //   query,
      //   searchType,
      //   searchMethod,
      //   searchArea,
      //   skipDuplicates,
      //   this.currentPage === 1 ? 0 : this.currentPage * this.pageSize,
      //   this.pageSize
      // );
      const { promise } = this.api.searchTextInBookAndLessons(searchParams);

      // this.query = query;
      // this.searchType = searchType;
      // this.searchMethod = searchMethod;
      // this.searchArea = searchArea;
      // this.skipDuplicates = skipDuplicates;

      console.log("start query to server");
      const response = await (await promise).json();
      console.log("end query to server",{response});
      if (response.length > 0) {
        this.results = response ? response[1] : [];
        this.totalResults = response[0]?.totalRecords || 0;
        // this.query = query;
      } else {
        this.cleanResults();
        this.displayNoResultsMessage = true;
      }

      // debugger;
    } catch (error) {
      console.log(error);
    }

    this.loading = false;

    return true;
  };
}
