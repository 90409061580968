import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
// import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import { PcSearchResultsStyled } from "./styled";
// import Search from "antd/lib/input/Search";
import { Checkbox, Divider, Row, Col, Input, Button } from "antd";
import Results from "./components/Results/Results";
import SearchStore from "../../../../../stores/SearchStore";
import { inject, observer } from "mobx-react";
import { SearchOutlined } from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {
  searchStore?: SearchStore;
}

class PcSearchResults extends React.PureComponent<Props, any> {
  render() {
    return (
      <PcSearchResultsStyled>
        <div className="AdvancedSearch">
          {/* <div className="br-bottom br-gray2 padding20 textRight">
            <Breadcrumbs
              routes={[
                { text: "ראשי", path: "#" },
                { text: "חיפוש מתקדם", path: "#" },
                { text: "תוצאות חיפוש", path: "#" },
              ]}
            />
          </div> */}
          <div
            className="search_container padding40 textRight marginCenter"
            style={{ maxWidth: "60%" }}
          >
            <Row>
              <Col className="" offset={4} span={4}>
                <div className="f18 marginTop10 underLine blue2 pointer">
                  <Link to={"search"} className="pointer blue2">
                    חיפוש מתקדם
                  </Link>
                </div>
              </Col>

              <Col className="" offset={2} span={14}>
                {/* <Search
                  // className="header_search"
                  placeholder="הקלד ביטוי, מילה או משפט לחיפוש במאגר"
                  // size="large"
                  onSearch={(value) => {
                    this.props.searchStore!.search_value = value;
                    this.props.searchStore!.search();
                  }}
                /> */}
                <Button
                  type="primary"
                  //  onSearch={(value) => {
                  //     this.props.searchStore!.search_value = value;
                  //     this.props.searchStore!.search();
                  //   }}
                >
                  <SearchOutlined />
                </Button>
                <Input placeholder="הקלד ביטוי, מילה או משפט לחיפוש במאגר" />

                <div className="textRight  marginHor20">
                  <Checkbox className="blue circle">הביטוי המדוייק</Checkbox>
                  <Checkbox className="blue marginRight20 circle">
                    מכיל את המילים או ביטוי דומה
                  </Checkbox>
                </div>
              </Col>
            </Row>
          </div>

          <Divider className="noMargin" />
          {/* <div
            className="search_container padding40 textRight marginCenter"
            style={{ maxWidth: "60%" }}
          /> */}
          <Results />
        </div>
      </PcSearchResultsStyled>
    );
  }
}
export default withRouter(inject("searchStore")(observer(PcSearchResults)));
