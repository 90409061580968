import { Row } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./Recommend.scss";

interface Props extends RouteComponentProps<any> {
  likes: number;
  title?: string;
  description?: string;
  image: string;
  link: string;
  action?: Array<string>;
}

class Recommend extends React.PureComponent<Props, any> {
  render() {
    let { likes, title, description, image, action, link } = this.props;
    return (
      <Row className="br-grayM br-bottom paddingHor20">
        {/* <Col className="flex" span={4}>
          <div className="f18 blue">{likes}</div>
          
          <HeartOutlined className="f18 padding5 blue hoverRed" />
        </Col> */}

        <Link to={link} className="f18 blue bold textRight flex">
          <img
            className="icon"
            src={"/images/Book-type/text/text-icon-Blue.svg"}
          />
          {title}
        </Link>
        {/* <Col className="textRight" span={12}>
          <div className="f18 blue bold">{title}</div>
          <div className="f15 blue">{description}</div>

          <div
            className="flex marginTop20 justifyStart {
"
          >
            {action !== undefined &&
              action.map(function (item, i) {
                return (
                  <div>
                    {item === "video" ? (
                      <img
                        alt="video-icon-Blue"
                        className="marginLeft10 marginHor10"
                        width="15"
                        src={"/images/Book-type/video/video-icon-Blue.svg"}
                      />
                    ) : null}

                    {item === "audio" ? (
                      <img
                        alt="video-icon-Blue"
                        className="margin10"
                        width="15"
                        src={"images/Book-type/Audio/audio-icon-blue.svg"}
                      />
                    ) : null}

                    {item === "book" ? (
                      <img
                        alt="video-icon-Blue"
                        className="margin10"
                        width="15"
                        src={"images/Book-type/text/text-icon-Blue.svg"}
                      />
                    ) : null}
                  </div>
                );
              })}
          </div>
        </Col> */}
        {/* <Col className="" span={8}>
          <img className="fright" width="90" src={image} />
        </Col> */}
      </Row>
    );
  }
}
export default withRouter(Recommend);
