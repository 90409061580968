import { InsertRowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import TreesStore from "../../../../../stores/TreesStore";
import UiStore from "../../../../../stores/UiStore";
import BookContent from "./components/BookContent/BookContent";
import BookHeader from "./components/BookHeader/BookHeader";
import BookInfo from "./components/BookInfo/BookInfo";
import BookMedia from "./components/BookMedia/BookMedia";
import TreeLinks from "./components/TreeLinks/TreeLinks";
import { PcBookStyled } from "./styled";

export type ITabOptions = "content" | "audio" | "treeLinks";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
  uiStore?: UiStore;
}

class PcBook extends React.PureComponent<Props, any> {
  currentTab: ITabOptions = this.props?.treesStore
    ?.currentTreeIsAudio
    ? "audio"
    : "content";
  htmlContent: any;

  scrollToContent = (nodeId: string) => {
    setTimeout(() => {
      const elem = document.getElementById(nodeId);
      // console.log({ nodeId, elem });
      if (elem) this.doScrolling(elem.offsetTop, 500);
    }, 300);
  };

  scrollToTop = () => {
    const elem: any = document.querySelector(".BookHeader");

    this.doScrolling(elem?.offsetTop || 0, 500);
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      currentTab: observable,
      htmlContent: observable
    });
  }

  doScrolling(elementY: number, duration: number) {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start: number;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  setCurrentTab = (currentTab: ITabOptions) => {
    this.currentTab = currentTab;
  };

  componentDidMount = () => {
    const { treesStore } = this.props;
    if (
      !treesStore?.currentNode.tableOfContent &&
      !treesStore?.loadingFetchTableOfContent
    ) {
      treesStore?.fetchTableOfContent(treesStore?.currentNode.bookLinkId);
    }

    if (
      !treesStore?.currentNode.details &&
      !treesStore?.loadingFetchContentDetails
    ) {
      treesStore?.fetchContentDetails(treesStore?.currentNode.bookLinkId);
    }
  };

  onPrint = () => {
    console.log(this.htmlContent);

    let mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow?.document.write(
      `<html><head><title> 
        ${document.title}
        </title><style> @import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css); @import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
        
  span {
    /* font-family: 'Open Sans', sans-serif !important; */
    font-family: "Open Sans Hebrew", serif !important;
    /* font-size:22px !important; */
  }

  h1 span,
  h1 {
    font-size: 50px !important;
    font-weight: 400;
  }
  h2 span,
  h2 {
    font-size: 34px !important;
  }
  h3 span,
  h3 {
    font-size: 30px !important;
  }
  h4 span,
  h4 {
    font-size: 22px !important;
  }
  .Miriam {
    font-family: MiriamLibre !important;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.7;
    text-align: justify;
    color: #1d455f;
    text-align: center;
  }
  .Quote span,
  .Quote {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-style: italic !important;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: justify;
    color: #1d455f;
  }
  p {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: justify;
    color: #1d455f;
  }
  p span {
    font-size: 22px !important;
  }
  h1 {
    font-size: 50px;
    line-height: 1.02;
    text-align: right;
    color: #1d455f;
    font-weight: 800;
  }
  h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.12;
    text-align: right;
    color: #1d455f;
  }
  h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    text-align: right;
    color: #1d455f;
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.64;
    text-align: right;
    color: #1d455f;
  }

  .a74 {
    text-align: center;
  }
  img {
    display: none;
  }
  .max-width {
    max-width: 800px;
    margin: 0 auto;
  }
        
        </style>`
    );
    mywindow?.document.write("</head><body >");
    mywindow?.document.write("<h1>" + document.title + "</h1>");
    mywindow?.document.write(this.htmlContent);
    mywindow?.document.write("</body></html>");

    mywindow?.document.close(); // necessary for IE >= 10
    mywindow?.focus(); // necessary for IE >= 10*/

    mywindow?.print();

    return true;
  };

  openTableContent = () => {
    // window.scrollTo(0, 0);
    debugger;
    this.props.uiStore!.displayTableOfContent = true;
  };

  render() {
    let __html;
    const { treesStore, match } = this.props;

    if (!treesStore?.currentTreeIsAudio) {
      treesStore!.setChapterId(match.params.chapter);

      // __html = treesStore!.getContent(treesStore!.currentChapterId);
      __html = treesStore!.currentChapterContent;

      this.htmlContent = __html;
      if (!treesStore?.contentLoading) {
        this.scrollToContent(match.params.chapter);
      }
    }

    const mediaLinks = treesStore?.mediaLinks;
    const treeLinks = treesStore?.treeLinks;

    return (
      <PcBookStyled>
        {!match.params.chapter &&
          treesStore?.currentNode?.tableOfContent &&
          !treesStore?.currentTreeIsAudio && (
            <Redirect
              to={`${match.url}/${treesStore?.currentNode?.tableOfContent[0].data}`}
            />
          )}

        <BookHeader
          currentTab={this.currentTab}
          hasMediaLinks={mediaLinks?.media?.length > 0}
          hasTreeLinks={treeLinks?.linkDetails?.tocId}
          setCurrentTab={this.setCurrentTab}
          title={
            treesStore?.currentNode?.details?.name ||
            treesStore?.currentNode?.details?.title
          }
          subTitle={treesStore?.currentNode?.details?.subtitle}
        />
        <div className="relative paddingHor40 flex">
          <Button
            className="toggleDisplayTableOfContent"
            onClick={() => this.openTableContent()}
          >
            <InsertRowLeftOutlined className="margin3 open_menu" />
            תוכן עניינים
          </Button>

          <div className="flex flex1">
            <div className="flex1 book-content-wrapper padding-mobile">
              {this.currentTab === "content" && (
                <BookContent
                  currentNode={treesStore?.currentNode}
                  loading={treesStore?.contentLoading || false}
                  html={__html}
                />
              )}
              {this.currentTab === "audio" && (
                <BookMedia mediaLinks={mediaLinks} />
              )}
              {this.currentTab === "treeLinks" && (
                <TreeLinks treeLinks={treeLinks} />
              )}
            </div>
            <BookInfo
              reliabilityLevel={
                treesStore?.currentNode?.details?.reliabilityLevel
              }
              editor={treesStore?.currentNode?.details?.editor}
              date={treesStore?.currentNode?.details?.date}
              writeLocation={treesStore?.currentNode?.details?.location}
              description={treesStore?.currentNode?.details?.description}
              print={treesStore?.currentTreeName === "audio" ? false : true}
              onPrint={this.onPrint}
            />
          </div>
        </div>
      </PcBookStyled>
    );
  }
}
export default withRouter(inject("treesStore", "uiStore")(observer(PcBook)));
