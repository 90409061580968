import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CategoriesOrTocStyled } from "./styled";
import { observer, inject } from "mobx-react";
import RsCategories from "../RsCategories/RsCategories";
import RsTableOfContent from "../RsTableOfContent/RsTableOfContent";
import TreesStore from "../../../../../stores/TreesStore";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}

class CategoriesOrToc extends React.PureComponent<Props, any> {
  render() {
    const { treesStore } = this.props;

    return (
      <CategoriesOrTocStyled>
        {treesStore?.currentNodeIsCategory ? (
          <RsCategories />
        ) : (
          <RsTableOfContent />
        )}
      </CategoriesOrTocStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(CategoriesOrToc)));
