import styled from "styled-components";

const HpCategoriesStyled = styled.div`
  padding-top: 0 !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export { HpCategoriesStyled };
