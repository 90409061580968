import styled from "styled-components";

const RightSideBarStyled: any = styled.div`
  box-shadow: 0px 10px 13px 1px #e6e6e6;
  background-image: url(/images/sidebar-menu/side_menu_img.png),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  background-repeat: no-repeat;
  background-position: top center;
  padding: 120px 4em 80px 4em;
  background-color: white;
  /* background-size: cover; */
  max-width: 480px;
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  z-index: 30;
  position: sticky;
  top: 0px;
  /* align-self: flex-start; */
  max-height: 100vh;
  font-size: 16px;
  max-width: calc(100vw / 3);

  @media only screen and (max-width: 700px) {
    &.responsive-current {
      position: fixed;
      display: block !important;
      width: 80%;
      max-width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 100000000;
    }
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  @media only screen and (min-width: 1250px) {
    max-width: 480px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 11px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 10px;
  }

  @media only screen and (max-width: 1000px) {
    .close-button {
      display: block;
    }
  }
`;

export { RightSideBarStyled };
