import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LsContactStyled } from "./styled";

interface Props extends RouteComponentProps<any> {}

class LsContact extends React.PureComponent<Props, any> {
  render() {
    return (
      <LsContactStyled className="textRight white left_menu padding60">
        <div className="contact-left-content">
          <div className="f30">מכון 'גַּל עֵינַי'</div>
          <div className="f18">פרטי התקשרות</div>
          <div className="marginTop40">
            <div className="flex marginTop20">
              <img
                alt="3123"
                className="fright"
                width="15"
                src="/images/contact/email.svg"
              />
              <div className="f4 fw400  marginRight20 f18">
                boazy4@gmail.com
              </div>
            </div>
          </div>
        </div>
      </LsContactStyled>
    );
  }
}
export default withRouter(LsContact);
