import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { HpCategoriesStyled } from "./styled";
import CategoryBox from "../CategoryBox/CategoryBox";
import TreesStore from "../../../../../../../stores/TreesStore";
import { inject, observer } from "mobx-react";
import { CommonCategoryBox } from "../../../../../../styled";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}

class HpCategories extends React.PureComponent<Props, any> {
  render() {
    const { treesStore } = this.props;
    return (
      <HpCategoriesStyled className="paddingVer20 paddingHor60 ">
        <div className="f2 blue bold marginHor20">עיון</div>
        <CommonCategoryBox className="justifyCenter maxWidthCategories">
          <CategoryBox
            categoryName={treesStore!.trees["books"].name}
            categoryChildrenCount={treesStore!.trees["books"].itemsCount}
            background={
              "linear-gradient(to bottom, rgba(210, 97, 97, 0.56), #d26161) , url(/images/categories/bg-books/pexels-photo-1148399.jpg)"
            }
            image={"/images/mix/books.svg"}
            link={treesStore!.trees["books"].slug}
          />
          <CategoryBox
            categoryName={treesStore!.trees["subjects"].name}
            categoryChildrenCount={treesStore!.trees["subjects"].itemsCount}
            background={
              "linear-gradient(to bottom, rgba(154, 146, 172, 0.56), #9a92ac) , url(/images/categories/bg-subjects/notebook-empty-design-paper-419635.jpg)"
            }
            image={"/images/mix/subjects.svg"}
            link={treesStore!.trees["subjects"].slug}
          />
          <CategoryBox
            categoryName={treesStore!.trees["lessons"].name}
            categoryChildrenCount={treesStore!.trees["lessons"].itemsCount}
            background={
              "linear-gradient(to bottom, rgba(124, 180, 212, 0.56), #7cb4d4),url(/images/categories/bglessons/auditorium-benches-chairs-class-207691.jpg)"
            }
            image={"/images/mix/classes-icon.svg"}
            link={treesStore!.trees["lessons"].slug}
          />
          <CategoryBox
            categoryName={treesStore!.trees["audio"].name}
            categoryChildrenCount={treesStore!.trees["audio"].itemsCount}
            background={
              "linear-gradient(to bottom, rgba(57, 122, 170, 0.56), #397aaa) , url(/images/categories/bg-audio/pexels-photo-1493004.jpg)"
            }
            image={"/images/mix/audio.svg"}
            link={treesStore!.trees["audio"].slug}
          />
        </CommonCategoryBox>
      </HpCategoriesStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(HpCategories)));
