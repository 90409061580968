import styled from "styled-components";

const TableOfContentStyled = styled.div`
  /* min-height: 100vh; */
  /* box-shadow: 0px 10px 13px 1px #e6e6e6; */
  /* background-image: url(/images/sidebar-menu/side_menu_img.png),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  background-repeat: no-repeat;
  padding: 134px 80px 0 80px; */
  width: 100%;
  /* max-width: 480px; */
  text-align: right;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  /* margin-bottom: 20px; */

  a {
    display: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 18px;
    background-color: white;

    .hasChildren {
      font-weight: 600;
    }
    li {
      margin: 0;
      font-weight: bold;
      span {
        border-bottom: 1px solid #c6c6c6;
        min-height: 53px;
        display: flex;
        align-items: center;
        &:first-child {
        }
      }

      .children-counter {
        flex: 1;
        justify-content: flex-end;
        padding-left: 10px;
      }
      span {
        span:last-child,
        span:first-child {
          border: none;
        }
      }
      ul {
        li {
          font-weight: normal;
        }
      }

      .children {
        li {
          span:first-child {
            padding-right: 20px;
          }
        }
      }
    }
  }
`;

export { TableOfContentStyled };
