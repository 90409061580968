import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import "./Footer.scss";
import { Row, Col, Divider } from "antd";
import { PhoneOutlined, GlobalOutlined } from "@ant-design/icons";
interface Props extends RouteComponentProps<any> {}

class Footer extends React.PureComponent<Props, any> {
  render() {
    return (
      <div className="Footer  no-padding-mobile">
        {false && (
          <>
            <Row className="padding20 footer hide-mobile">
              <Col span={5} className="paddingBottom40">
                <div className="flex">
                  {/* <Icon className="marginLeft10" type="phone" /> */}
                  <PhoneOutlined className="margin3" />
                  <div className="gray">034563456</div>
                </div>
                <div className="flex">
                  {/* <Icon className="margin3" type="global" /> */}
                  <GlobalOutlined className="margin3" />
                  <div className="gray">info@sfarim.co.il</div>
                </div>
              </Col>
              <Col span={5}>
                <div className="bold textRight marginBottom20">צור קשר</div>
                <div className="flex">
                  {/* <Icon className="margin3" type="phone" /> */}
                  <PhoneOutlined className="margin3" />
                  <div className="gray">034563456</div>
                </div>
                <div className="flex">
                  {/* <Icon className="margin3" type="global" /> */}
                  <GlobalOutlined className="margin3" />
                  <div className="gray">info@sfarim.co.il</div>
                </div>
              </Col>
              <Col span={4}>
                <Divider type="vertical" className="br_footer" />
              </Col>

              <Col span={5}>
                <Link className="gray marginLeft40 link-header" to="/">
                  ראשי
                </Link>
                <Link className="gray marginLeft40 link-header" to="/search">
                  חיפוש מתקדם
                </Link>

                <Link className="gray marginLeft40 link-header" to="/contact">
                  צור קשר
                </Link>
              </Col>

              <Col span={5}>
                <Link to="/">
                  <img
                    className="fright"
                    width="120"
                    src="/svgs/logo.svg"
                    alt="logo"
                  />
                </Link>
              </Col>
            </Row>

            <div className="hide-desktop   br-grayM">
              <Link to="/">
                <img
                  className="marginCenter padding-mobile"
                  width="100"
                  src="/svgs/logo.svg"
                />
              </Link>
            </div>
          </>
        )}

        <div className="padding20 br-top br-grayM textCenter gray">
          כל הזכויות שמורות לעמותת גל עיני. אין לשכפל, להעביר או להעתיק.
        </div>
      </div>
    );
  }
}
export default withRouter(Footer);
