import styled from "styled-components";

const RsTableOfContentStyled = styled.div`
  max-height: 100vh;
  /* overflow-y: auto; */
  .ant-affix {
    /* height: 100vh !important; */
    /* overflow-y: auto; */
  }
`;

export { RsTableOfContentStyled };
