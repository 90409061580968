import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PcHomePageStyled } from "./styled";
import HeaderSearch from "./components/HeaderSearch/HeaderSearch";

import New from "./components/New/New";
import HpCategories from "./components/HpCategories/HpCategories";
import RsHomePage from "../../../RightSideBar/components/RsHomePage/RsHomePage";

interface Props extends RouteComponentProps<any> {}

class PcHomePage extends React.PureComponent<Props, any> {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <PcHomePageStyled className="flex1">
        <HeaderSearch />

        <HpCategories />

        <div className="hide-desktop padding20 RsHomePageMobile">
          <RsHomePage />
        </div>

        <New />
      </PcHomePageStyled>
    );
  }
}
export default withRouter(PcHomePage);
