import styled from "styled-components";

const MiddleStyled = styled.div`
  min-height: calc(100vh - 407px);

  z-index: 4;

  @media only screen and (min-width: 1000px) {
    position: relative;
  }
  /* a {
    display: inherit;
  } */
  &.page-type-books {
    .tableOfContent {
      a.selected {
        background-color: #942e2f;
      }
      a:not(.selected):hover {
        background-color: #ee9293;
        background-color: #ececec;
      }
    }
  }
  &.page-type-subjects {
    .tableOfContent {
      a.selected {
        background-color: #635482;
      }
      a:not(.selected):hover {
        background-color: #c0b5d5;
        background-color: #ececec;
      }
    }
  }
  &.page-type-lessons {
    .tableOfContent {
      a.selected {
        background-color: #5c91ab;
      }
      a:not(.selected):hover {
        background-color: #78c3e9;
        background-color: #ececec;
      }
    }
  }
  &.page-type-audio {
    .tableOfContent {
      a.selected {
        background-color: #1c577c;
      }
      a:not(.selected):hover {
        background-color: #308cc6;
        background-color: #ececec;
      }
    }
  }
  &.page-type-audio,
  &.page-type-lessons,
  &.page-type-subjects,
  &.page-type-books {
    transition: none;
    .tableOfContent {
      a.selected {
        * {
          color: white;
        }
      }
      a:not(.selected):hover {
        * {
          color: #1d455f;
        }
      }
    }
  }
`;

export { MiddleStyled };
