import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
} from "react-router-dom";
import { PageContentWrapperStyled } from "./styled";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import PcContact from "./components/PcContact/PcContact";
import PcHomePage from "./components/PcHomePage/PcHomePage";
import PcSearch from "./components/PcSearch/PcSearch";
import PcSearchResults from "./components/PcSearchResults/PcSearchResults";
import { observer } from "mobx-react";
import TreeNode from "./components/TreeNode/TreeNode";
import About from "./components/About/About";
import PcNewsletter from "./components/PcNewsletter/PcNewsletter";
import PcNeedHelp from "./components/PcNeedHelp/PcNeedHelp";

interface Props extends RouteComponentProps<any> {}

class PageContentWrapper extends React.PureComponent<Props, any> {
  render() {
    return (
      <PageContentWrapperStyled className="flex1">
        <Switch>
          <Route
            path={[
              "/books/:id/:chapter",
              "/books/:id",
              "/books",

              "/lessons/:id/:rest+",
              "/lessons/:id",
              "/lessons",

              "/subjects/:id/:rest+",
              "/subjects/:id",
              "/subjects",

              "/audio/:id/:rest+",
              "/audio/:id",
              "/audio",

              "/search",
            ]}
            render={({ match: { url } }) => <Breadcrumbs />}
          />
        </Switch>

        <Switch>
          <Route exact path={"/"} component={PcHomePage} />
          <Route path={"/search-results"} component={PcSearchResults} />
          <Route path={"/search/advanced"} component={PcSearch} exact />
          <Route path={"/search"} component={PcSearch} exact />
          <Route path={"/contact"} component={PcContact} />
          <Route path={"/newsletter"} component={PcNewsletter} />
          <Route path={"/need-help"} component={PcNeedHelp} />
          <Route path={"/about"} component={About} />
          <Route
            path={[
              "/books/:id/:chapter",
              "/books/:id",
              "/books",

              "/lessons/:id/:chapter",
              "/lessons/:id",
              "/lessons",

              "/subjects/:id/:chapter",
              "/subjects/:id",
              "/subjects",

              "/audio/:id/:chapter",
              "/audio/:id",
              "/audio",
            ]}
            component={TreeNode}
          />
        </Switch>
      </PageContentWrapperStyled>
    );
  }
}
export default withRouter(observer(PageContentWrapper));
