import { Button, Form, Input } from "antd";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Validation from "../../../../../services/validation";
import { PcNeedHelpStyled } from "./styled";
const { TextArea } = Input;

interface Props extends RouteComponentProps<any> {}

class PcNeedHelp extends React.PureComponent<Props, any> {
  success_message: string = "";
  error_message: string = "";

  onFinish = async (values: any) => {
    values.header = "הרשמה ל newsletter";
    values.sendToAdminEmailsList = "noamfr@gmail.com;noam@face-int.com";

    let url = `https://galeinai.org.il:8443/api/AskForHelp`;

    try {
      const rawResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      console.log(rawResponse);
      if (rawResponse.status === 200) {
        this.success_message = "תודה רבה, ההודעה נשלחה בהצלחה";
      } else {
        this.error_message = "היתה בעיה בשליחת ההודעה";
      }
    } catch (error) {
      console.log(error);
    }
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      success_message: observable,
      error_message: observable
    });
  }

  render() {
    return (
      <PcNeedHelpStyled>
        <div className="search_container padding40 textRight marginCenter">
          <div className="blue textRight bold f30">עזרה בחיפוש טכנים באתר.</div>
          <div className="blue textRight  f20">חזרה תוך 48 שעות.</div>

          <Form onFinish={this.onFinish} className="marginTop60 form">
            <div className="flex">
              <div className="marginBottom20 half">
                <Form.Item
                  label="שם פרטי"
                  className="full"
                  name="name"
                  rules={[{ required: true, message: "אנא הוסף שם פרטי" }]}
                >
                  <Input
                    type={"text"}
                    className="radius padding10 marginTop10"
                  />
                </Form.Item>
              </div>
              <div className="marginBottom20 half marginRight20">
                <Form.Item
                  label="שם משפחה"
                  className="full"
                  name="lastName"
                  rules={[{ required: true, message: "אנא הוסף שם משפחה" }]}
                >
                  <Input
                    type={"text"}
                    className="radius padding10 marginTop10"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex">
              <div className="marginBottom20 full">
                <Form.Item
                  label="כתובת דוא”ל"
                  className="full"
                  name="userEmailAddress"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && Validation.validateEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("אנא הוסף אימייל תקין");
                      },
                    }),
                  ]}
                >
                  <Input
                    type={"text"}
                    className="radius padding10 marginTop10"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex">
              <div className="marginBottom20 full">
                <Form.Item
                  label="נשמח לשמוע את השאלה שלך"
                  className="full"
                  name="content"
                  rules={[{ required: true, message: "אנא תאר את מהות הפניה" }]}
                >
                  <TextArea
                    onChange={(e) => console.log("22")}
                    className="marginBottom20 padding10 marginTop10"
                    placeholder="הודעה"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="marginBottom20 full">
              <Button
                htmlType="submit"
                type="primary"
                className="b-blueT marginCenter textCenter white   radius pointer"
                // onClick={this.isUpdate ? this.update : this.create}
              >
                שלח
              </Button>
            </div>
          </Form>

          {this.success_message.length > 1 ? (
            <div className="message_green">{this.success_message}</div>
          ) : (
            ""
          )}

          {this.error_message.length > 1 ? (
            <div className="red f20 bold">{this.error_message}</div>
          ) : (
            ""
          )}
        </div>
      </PcNeedHelpStyled>
    );
  }
}
export default withRouter(observer(PcNeedHelp));
