import styled from "styled-components";

const BookHeaderStyled = styled.div`
  padding: 50px 60px 0;
  position: relative;

  .toggleDisplayTableOfContent {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    background: #1d455f;
    border: 1 px solid #fff;
    color: #fff;
    border-top: none;
    font-size: 16px;
  }

  @media only screen and (max-width: 1000px) {
    .toggleDisplayTableOfContent {
      display: block;
    }
  }
  .button-group {
    display: flex;
    line-height: 2.5;
  }
  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 2px 30px;
  }

  .active.btn {
    background: #fff;
    color: #68abcc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export { BookHeaderStyled };
