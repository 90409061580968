import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import TreesStore from "../../../../../stores/TreesStore";
import { RightMenuTitleTitleStyled } from "../../../../styled";
import TableOfContent from "../../../../TableOfContent/TableOfContent";
import { RsCategoriesStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  collectionType?: "string";
  treesStore?: TreesStore;
}
class RsCategories extends React.PureComponent<Props, any> {
  render() {
    const keys: any =
      this.props.match.url.split("/").filter((route: string) => route.length) ||
      [];
    return (
      <RsCategoriesStyled className="RsCategoriesStyled">
        <RightMenuTitleTitleStyled className="textRight">
          עיון
        </RightMenuTitleTitleStyled>
        <TableOfContent
          // collectionType={"subjects"}
          items={[
            {
              selected: keys[1] === "books",
              text: "ספרים",
              image: "/images/mix/audio.svg",
              data: "/books",
              isRegularLink: true,
              uuid: uuidv4(),
            },
            // {
            //   selected: keys[1] === "subjects",
            //   text: "נושאים",
            //   data: "/subjects",
            //   isRegularLink: true,
            //   uuid: uuidv4(),
            // },
            {
              selected: keys[1] === "lessons",
              text: "שיעורים",
              data: "/lessons",
              isRegularLink: true,
              uuid: uuidv4(),
            },
            {
              selected: keys[1] === "audio",
              text: "אודיו",
              data: "/audio",
              isRegularLink: true,
              uuid: uuidv4(),
            },
          ]}
          activeNode={`/${this.props.treesStore!.currentTree?.slug}`}
        />
      </RsCategoriesStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(RsCategories)));
