import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
} from "react-router-dom";
import { MiddleStyled } from "./styled";
import LeftSideBar from "./LeftSideBar/LeftSideBar";
import PageContentWrapper from "./PageContentWrapper/PageContentWrapper";
import RightSideBar from "./RightSideBar/RightSideBar";
import { observer, inject } from "mobx-react";
import TreesStore from "../../stores/TreesStore";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}

class Middle extends React.PureComponent<Props, any> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { treesStore } = this.props;

    return (
      <MiddleStyled
        className={`MiddleStyled flex flex1 page-type-${
          treesStore!.currentTree.slug
        }`}
      >
        <Switch>
          <Route
            path={[
              "/books/:id/:rest+",
              "/books/:id",
              "/books",

              "/lessons/:id/:rest+",
              "/lessons/:id",
              "/lessons",

              "/subjects/:id/:rest+",
              "/subjects/:id",
              "/subjects",

              "/audio/:id/:rest+",
              "/audio/:id",
              "/audio",
            ]}
            component={RightSideBar}
          />
          <Route exact path={"/"} component={RightSideBar} />
        </Switch>
        <PageContentWrapper />
        <Switch>
          <div className="hide-mobile">
            <Route
              path={["/contact", "/newsletter", "/need-help"]}
              component={LeftSideBar}
            />
          </div>
        </Switch>
      </MiddleStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(Middle)));
