import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RsTableOfContentStyled } from "./styled";
import TableOfContent from "../../../../TableOfContent/TableOfContent";
import { observer, inject } from "mobx-react";
import TreesStore from "../../../../../stores/TreesStore";
import { RightMenuTitleTitleStyled } from "../../../../styled";
import { observable, makeObservable } from "mobx";
import { Affix, Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import UiStore from "../../../../../stores/UiStore";
interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
  uiStore?: UiStore;
}
class RsTableOfContent extends React.PureComponent<Props, any> {
  baseUrl: string = "";
  activeNodeToken: string = "";
  componentDidMount = () => {
    this.baseUrl = `/${this.props!.treesStore!.currentTree!.slug}/${
      this.props.match.params.id
    }`;
  };

  scrollToTop = () => {
    const elem: any = document.querySelector(".BookHeader");
    // console.log({ offsetTop: elem?.offsetTop });
    // console.log("offsetTop", elem, elem?.offsetTop);
    this.doScrolling(elem?.offsetTop || 0, 500);
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      baseUrl: observable,
      activeNodeToken: observable
    });
  }

  doScrolling(elementY: number, duration: number) {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start: number;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  onClickTOCChild = () => {
    this.props?.uiStore?.toggleDisplayTableOfContent();
  };

  render() {
    const { treesStore } = this.props;
    return (
      <>
        <RsTableOfContentStyled className="RsTableOfContent">
          {/* <Affix offsetTop={0}> */}
          <RightMenuTitleTitleStyled className="textRight">
            תוכן עניינים
          </RightMenuTitleTitleStyled>
          {treesStore?.loadingFetchTableOfContent ? (
            <img src="/images/loading-0.8s-207px.svg" alt="loading" />
          ) : (
            <TableOfContent
              onClickTOCChild={this.onClickTOCChild}
              isHashTagLinks={true}
              items={treesStore?.currentNode?.tableOfContent || []}
              baseUrl={this.baseUrl}
              activeNode={this.props.match.params.chapter}
              isMyChild={treesStore?.isMyChild || (() => {})}
            />
          )}
          {/* </Affix> */}
        </RsTableOfContentStyled>
        <Button className="back-to-top b-blue white" onClick={this.scrollToTop}>
          <ArrowUpOutlined /> חזור למעלה
        </Button>
      </>
    );
  }
}
export default withRouter(inject("treesStore", "uiStore")(observer(RsTableOfContent)));
