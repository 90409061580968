class Validation {
  static validateEmail(email: any) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static validatePassword(password: any) {
    return password.length > 4;
  }

  static validatePhone(phoneNumber: any) {
    return phoneNumber.length > 6;
  }

  static allowNumbersOnly(stringToCheck: any) {
    const specialCharRegex = new RegExp("^[0-9]*$");
    const pressedKey = String.fromCharCode(
      !stringToCheck.charCode ? stringToCheck.which : stringToCheck.charCode
    );

    if (!specialCharRegex.test(pressedKey)) {
      stringToCheck.preventDefault();
      return false;
    }
  }

  static max8attendens(attended: any) {
    return Number(attended) <= 8;
  }

  static freePlacesLessThanTotalInvites(
    freePlaces: any,
    maxParticipants: number
  ) {
    console.log(["maxParticipants", maxParticipants]);
    if (maxParticipants) {
      return Number(freePlaces) <= maxParticipants;
    } else {
      return Number(freePlaces) <= 6;
    }
  }

  static facebookUrlValidation(facebookUrlValidation: any) {
    if (facebookUrlValidation !== "") {
      if (facebookUrlValidation.indexOf("https://www.facebook.com/") == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}

export default Validation;
