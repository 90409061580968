import styled from "styled-components";

const RsCategoriesStyled = styled.div`
  flex-direction: column;
  flex: 1;
`;
const RsCategoriesTitleStyled = styled.div`
  padding-bottom: 27px;
  color: #c6c6c6;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.blue};
  text-align: right;
`;
export { RsCategoriesStyled, RsCategoriesTitleStyled };
