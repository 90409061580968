import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { CategoryBoxStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  categoryName: string;
  categoryChildrenCount: Number;
  background: string;
  link: string;
  image?: string;
}

class CategoryBox extends React.PureComponent<Props, any> {
  render() {
    const {
      categoryName,
      categoryChildrenCount,
      background,
      image,
      link,
    } = this.props;
    return (
      <CategoryBoxStyled
        className="category-box-content"
        style={{ background: background }}
      >
        {image ? <ReactSVG className="icon_box_1" src={image} /> : null}
        <Link to={link}>
          <span className="f30 bold tablet-f25">{categoryName}</span>
          <span className="f18 tablet-f14">({categoryChildrenCount}) </span>
        </Link>
      </CategoryBoxStyled>
    );
  }
}
export default withRouter(CategoryBox);
