import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Redirect,
  Link,
} from "react-router-dom";
import { SearchPanelStyled } from "./styled";
import {
  Divider,
  Input,
  Button,
  Radio,
  Select,
  AutoComplete,
  Form,
  Checkbox,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SearchStore from "../../../../../../../stores/SearchStore";
import { inject, observer } from "mobx-react";
import debounce from "../../../../../../../utils/debounce";
import { action, computed, observable, makeObservable } from "mobx";
import { ReactComponent as ClassesIconLogo } from "../../../../../../../assets/icons/classes-icon.svg";
import { ReactComponent as SubjectsLogo } from "../../../../../../../assets/icons/subjects.svg";
import { ReactComponent as BooksLogo } from "../../../../../../../assets/icons/books.svg";
import { ReactComponent as AudioLogo } from "../../../../../../../assets/icons/audio.svg";
import { queryString } from "../../../../../../../services/build.query";
import qs from "qs";
import { FormInstance } from "antd/lib/form";
import SearchTypeCheckbox from "./components/SearchTypeCheckbox/SearchTypeCheckbox";
import { type } from "os";

const { Option } = Select;
interface Props extends RouteComponentProps<any> {
  isHomepage?: boolean;
  // mode?: "advanced" | "regular";
  searchStore?: SearchStore;
  initialValues: any;
  mode: formMode;
}

export enum formMode {
  regular,
  advanced,
}
class SearchPanel extends React.PureComponent<Props, any> {
  formRef = React.createRef<FormInstance>();

  formMode: formMode = formMode.regular;

  redirect: boolean = false;
  query: string = "";
  searchType: any = 1;
  searchMethod: any = "2";

  skipDuplicates: string = "1";

  onSearch = (value: any) => {
    this.props!.searchStore!.query = value;
  };

  onSearchDebounce = debounce(this.onSearch);

  onChange = (event: any) => {
    this.onSearchDebounce(event.target.value);
  };

  getSearchParams = () =>
    qs.parse(this.props.location.search.replace("?", ""), {
      decoder: function (value: any) {
        if (/^(\d+|\d*\.\d+)$/.test(value)) {
          return parseFloat(value);
        }

        let keywords: any = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
        };
        if (value in keywords) {
          return keywords[value];
        }

        return decodeURI(value);
      },
    });

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      formMode: observable,
      redirect: observable,
      query: observable,
      searchType: observable,
      searchMethod: observable,
      skipDuplicates: observable,
      searchPanelMode: computed,
      onRouteChanged: action,
      updateFormMode: action
    });
  }

  get searchPanelMode() {
    return this.props!.searchStore!.results?.length > 0 ||
      this.props!.searchStore!.loading
      ? formMode.regular
      : this.props.mode;
  }
  onRouteChanged() {
    const searchParams: any = this.getSearchParams();

    if (!searchParams.query) {
      return;
    }

    this.formRef.current!.setFieldsValue({ query: searchParams.query });
    this.props!.searchStore!.search(searchParams);
  }

  updateFormMode = (mode: any) => {
    this.formMode = mode;
  };

  componentDidMount = () => {
    this.onRouteChanged();

    const searchParams: any = this.getSearchParams();
    this.updateFormMode(
      typeof searchParams.query !== "undefined" && searchParams.query.length > 0
        ? formMode.regular
        : this.props.mode
    );
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.props.searchStore!.results = [];
      this.onRouteChanged();
    }
  }

  search = async (values: any = {}) => {
    this.props!.searchStore!.currentPage = 1;
    const searchParams = {
      currentPage: 1,
      pageSize: 50,
      ...this.props.initialValues,
      ...values,
      searchArea: {
        books: typeof values.books !== "undefined" ? values.books : true,
        subjects:
          typeof values.subjects !== "undefined" ? values.subjects : true,
        audio: typeof values.audio !== "undefined" ? values.audio : true,
        lessons: typeof values.lessons !== "undefined" ? values.lessons : true,
      },
    };

    this.props.history.push(`/search/advanced?${qs.stringify(searchParams)}`);
  };

  render() {
    return (
      <SearchPanelStyled
        className={
          " " +
          (this.searchPanelMode === formMode.advanced ? "max-width-form" : "")
        }
        hasResults={this.props!.searchStore!.results?.length > 0}
      >
        <Form
          ref={this.formRef}
          initialValues={this.props.initialValues}
          onFinish={this.search}
        >
          {this.searchPanelMode === formMode.advanced && (
            <div className="bold marginHor40 f24">חיפוש מתקדם</div>
          )}

          <div className="flex InputPanel  padding-mobile">
            <div className="InputSearch InputWrapper">
              <Form.Item
                name="query"
                // rules={[
                //   { required: true, message: "Please input your password!" },
                // ]}
              >
                <Input
                  // style={{ width: 300 }}
                  className="input_search"
                  placeholder={
                    !this.props.isHomepage
                      ? "חיפוש של ביטוי מדויק"
                      : "חיפוש של ביטוי מדויק בשמות הספרים/שיעורים/ערכים"
                  }
                  // value={this.query}
                  // onChange={(event: any) => (this.query = event.target.value)}
                />
              </Form.Item>

              {this.searchPanelMode !== formMode.advanced && (
                <Button
                  htmlType="submit"
                  // onClick={this.search}
                  disabled={this.props!.searchStore!.loading}
                  loading={this.props!.searchStore!.loading}
                >
                  {!this.props!.searchStore!.loading && <SearchOutlined />}
                </Button>
              )}
            </div>

            {this.searchPanelMode === formMode.regular ? (
              <Link
                to="/search/advanced"
                className={`white search_text blue`}
                onClick={() => {
                  this.props.searchStore?.cleanResults();
                }}
              >
                חיפוש מתקדם
              </Link>
            ) : null}
          </div>

          {this.searchPanelMode === formMode.advanced && (
            <div className="textRight marginHor20 InputWrapper">
              <Form.Item
                name="searchMethod"
                // rules={[
                //   { required: true, message: "Please input your password!" },
                // ]}
              >
                <Radio.Group
                // onChange={(e) => {
                //   this.searchMethod = e.target.value;
                // }}
                // value={this.searchMethod}
                >
                  <Radio value={"1"}>הביטוי במדויק</Radio>
                  <Radio value={"2"}>הביטוי בדילוג מילים</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          )}

          {this.searchPanelMode === formMode.advanced && (
            <Divider className="marginHor30" />
          )}

          {this.searchPanelMode === formMode.advanced && (
            <>
              <div className="SearchOption  textRight InputWrapper">
                <div className="marginHor20">
                  <div className="marginHor10 bold">היכן לחפש</div>
                  <Form.Item
                    name="searchType"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input your password!",
                    //   },
                    // ]}
                  >
                    <Select
                      defaultValue="3"
                      style={{ width: "100%" }}
                      // onChange={(e) => {
                      //   console.log({ e });
                      //   this.searchType = e;
                      // }}
                    >
                      <Option value="3"> שמות הספרים / ערכים / שיעורים </Option>
                      <Option value="2" disabled> בכותרות שבתוכן </Option>
                      <Option value="1"> בתוכן</Option>
                      <Option value="4" disabled> תאריך ומיקום השיעור </Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <Divider className="noMargin marginHor30" />
            </>
          )}

          {this.searchPanelMode === formMode.advanced && (
            <>
              <div className="FileType  ">
                <div className="bold">סוג הקובץ</div>
                <div>בחר אחד או כמה מסוגי הקבצים</div>
                <div className="marginHor20 file-type-options grid">
                  <SearchTypeCheckbox
                    name="books"
                    backgroundClass="br-red"
                    textLabel="ספרים"
                    logo={<ClassesIconLogo />}
                  />
                  <SearchTypeCheckbox
                    name="subjects"
                    backgroundClass="br-pirple"
                    textLabel="ערכים"
                    logo={<SubjectsLogo />}
                  />
                  <SearchTypeCheckbox
                    name="lessons"
                    backgroundClass="br-blue2"
                    textLabel="שיעורים"
                    logo={<BooksLogo />}
                  />
                  <SearchTypeCheckbox
                    name="audio"
                    backgroundClass="br-blue"
                    textLabel="אודיו"
                    logo={<AudioLogo />}
                  />
                </div>
              </div>

              {this.props!.searchStore!.displayNoResultsMessage && (
                <div className="red center">לא נמצאו תוצאות לביטוי שחיפשת</div>
              )}

              <div className="textRight">*החיפוש מציג תוצאה אחת מכל פיסקה</div>

              <Button
                loading={this.props!.searchStore!.loading}
                htmlType="submit"
                size={"large"}
                className="b-blueT marginCenter marginHor30  textCenter white  full f20 radius pointer"
                // onClick={this.search}
              >
                חפש
              </Button>
              <div className="textRight bold marginBottom30">
                <Link to="/need-help">
                  זקוק לעזרה שלנו באיתור מקורות לנושא? פנה אלינו
                </Link>
              </div>
            </>
          )}
        </Form>
      </SearchPanelStyled>
    );
  }
}
export default withRouter(inject("searchStore")(observer(SearchPanel)));
