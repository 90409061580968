import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { TreeLinksStyled } from "./styled";
import { observer, inject } from "mobx-react";
import TreesStore from "../../../../../../../stores/TreesStore";

interface Props extends RouteComponentProps<any> {
  treeLinks: any;
  treesStore?: TreesStore;
}

class TreeLinks extends React.PureComponent<Props, any> {
  render() {
    const { treesStore, treeLinks } = this.props;
    console.log({ treeId: treeLinks.linkDetails.treeId });
    return (
      <TreeLinksStyled>
        <div className="f24">תוכן נוסף המקושר לכאן</div>
        <ul>
          <li>
            <Link
              className="f18"
              to={`/${treesStore?.getTreeSlug(treeLinks.linkDetails.treeId)}/${
                treeLinks.linkDetails.tocId
              }`}
            >
              {treeLinks.linkDetails.tocName}
            </Link>
          </li>
        </ul>
      </TreeLinksStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(TreeLinks)));
