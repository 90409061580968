import styled from "styled-components";

const RsHomePageStyled = styled.div`
  .about-text {
    overflow-y: auto;
    text-align: justify !important;
    p {
      line-height: initial;
      margin: 20px 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  @media only screen and (min-width: 600px) {
    .about-text {
      max-height: 500px;
      overflow-y: auto;
    }
  }
`;

export { RsHomePageStyled };
