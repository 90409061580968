import { observable, computed, action, makeObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

class BaseStore {
  trees: any = {
    books: {
      treeId: 1480662372,
      name: "ספרים",
      slug: "books",
      itemsCount: 0,
      children: [],
      colors: ["#942E2F", "#EE9293", "#D55759", "#D03538", "#A95355"],
      audio: {},
    },
    audio: {
      treeId: 1466506535,
      name: "אודיו",
      slug: "audio",
      itemsCount: 0,
      children: [],
      colors: ["#659ABB", "#246F9E", "#14679B", "#1C577C", "#308CC6"],
      audio: {},
    },
    subjects: {
      treeId: 1479482716,
      name: "ערכים",
      slug: "subjects",
      itemsCount: 0,
      children: [],
      colors: ["#C0B5D5", "#6F6483", "#927FB5", "#635482", "#9087A1"],
      audio: {},
    },
    lessons: {
      treeId: 2759928088,
      name: "שיעורים",
      slug: "lessons",
      itemsCount: 0,
      children: [],
      colors: ["#89C7E4", "#5C91AB", "#53A5CD", "#78C3E9", "#68ABCC"],
      audio: {},
    },
    news: {
      treeId: 942617850,
      name: "חדש באתר",
      slug: "news",
      itemsCount: 0,
      children: [],
      audio: {},
      colors: ["#68abcc", "#917fb5", "#ee9192", "#2f8cc6"],
    },
    recommended: {
      treeId: 2753440010,
      name: "המומלצים שלנו",
      slug: "recommended",
      itemsCount: 0,
      children: [],
      audio: {},
    },
  };

  items: any = [];

  /**
   * Fetch book by chapter or fullbook
   * 0 - by chapter
   * 1 - full book
   */
  fetchBookMode: any = 0;
  currentTreeName: "books" | "lessons" | "subjects" | "audio" = "books";

  constructor() {
    makeObservable(this, {
      trees: observable,
      items: observable,
      fetchBookMode: observable,
      currentTreeName: observable,
      itemsCount: computed,
      currentTree: computed,
      setCurrentTreeName: action
    });
  }

  get itemsCount() {
    return this.getChildrenCounter({
      children: this.items,
    });
  }

  get currentTree() {
    return this.trees[this.currentTreeName];
  }

  setCurrentTreeName = (
    name: "books" | "lessons" | "subjects" | "audio"
  ) => {
    this.currentTreeName = name;
    this.fetchBookMode = name === "lessons" ? 1 : 0;
  };

  getTreeById = (id: string) => {
    let tree = null;
    switch (Number(id)) {
      case Number(this.trees.books.treeId):
        tree = this.trees.books;
        break;
      case Number(this.trees.audio.treeId):
        tree = this.trees.audio;
        break;
      case Number(this.trees.subjects.treeId):
        tree = this.trees.subjects;
        break;
      case Number(this.trees.lessons.treeId):
        tree = this.trees.lessons;
        break;
    }
    return tree;
  };

  getTreeSlug = (treeNumber: any) => {
    let slug = null;
    if (parseInt(this.trees.books.treeId) === parseInt(treeNumber)) {
      slug = "books";
    }

    if (parseInt(this.trees.audio.treeId) === parseInt(treeNumber)) {
      slug = "audio";
    }

    if (parseInt(this.trees.subjects.treeId) === parseInt(treeNumber)) {
      slug = "subjects";
    }

    if (parseInt(this.trees.lessons.treeId) === parseInt(treeNumber)) {
      slug = "lessons";
    }

    if (parseInt(this.trees.news.treeId) === parseInt(treeNumber)) {
      slug = "news";
    }

    if (parseInt(this.trees.recommended.treeId) === parseInt(treeNumber)) {
      slug = "recommended";
    }
    return slug;
  };
  feelObject = (
    obj: any,
    parent: any = null,
    treeSlug: string | null = null,
    tree: any
  ) => {
    let route;
    if (obj.data !== "toc_h0_0") {
      obj.parent =
        (parent && Object.assign({}, { ...parent }, { children: null })) ||
        null;
      obj.childrenCount = this.getChildrenCounter(obj);
      obj.uuid = uuidv4();

      const treeLink = tree.treeLinks.find(
        (link: any) => link.tocId === obj.data
      );

      if ((treeSlug === "books" || treeSlug === "lessons") && treeLink) {
        route = `/${this?.getTreeSlug(parseInt(treeLink.linkDetails.treeId))}/${
          treeLink.linkDetails.tocId
        }`;

        obj.tocId = treeLink.linkDetails.tocId;
        // console.log({
        //   obj,
        //   nodeName: obj.text,
        //   id: obj.data,
        //   treeLink,
        //   // route,
        //   tree: this?.getTreeSlug(parseInt(treeLink.linkDetails.treeId)),
        //   treeId: parseInt(treeLink.linkDetails.treeId),
        // });
      }

      if (obj.data === "toc_h1_h4_h1_0_62") {
        obj.viewType = 4;
      }
      if (obj.tocLinkId === "1") {
        delete obj.tocLinkId;
      }
      obj.route = route || `${treeSlug}/${obj.data}/${obj.tocLinkId || ""}`;
      obj.contents = {};
      obj.treeSlug = treeSlug;
    }

    if (obj.children) {
      obj.children = obj.children.map((child: any) =>
        this.feelObject(
          child,
          obj.data !== "toc_h0_0" ? obj : null,
          treeSlug,
          tree
        )
      );
    }
    return obj;
  };

  getChildrenCounter = (item: any) => {
    return !item.children
      ? null
      : item?.children?.reduce((acc: any, _item: any) => {
          return acc + (_item.children ? this.getChildrenCounter(_item) : 1);
        }, 0);
  };

  getChildrenByKey = (items: any = [], key: string | null) => {
    return key === null
      ? items
      : items?.reduce((acc: any, _item: any) => {
          acc = acc =
            _item.data === key
              ? acc.concat(_item.children)
              : acc.concat(this.getChildrenByKey(_item.children, key));
          return acc;
        }, []);
  };

  findDeep = (collection: any, str: any, dataKey = "data"): any => {
    for (var i = 0; i < collection.length; i++) {
      const obj = collection[i];
      if (obj[dataKey] === str) {
        return obj;
      }
      let match;
      if (obj.children && (match = this.findDeep(obj.children, str, dataKey)))
        return match;
    }

    return undefined;
  };

  findNode = (nodeToken: string, collection = this.currentTree.children) => {
    return this.currentTree && this.findDeep(collection, nodeToken);
  };

  isContentNode = (nodeToken: string): boolean => {
    const node = this.findNode(nodeToken);

    return node ? typeof node.children === "undefined" : false;
  };

  findNodeInTree = (tree: any, key: string) => {
    return this.findDeep(tree.children, key);
  };
}
export default BaseStore;
