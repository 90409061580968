import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider, observer } from "mobx-react";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import Api from "./services/Api";
import Middle from "./common/Middle/Middle";
import TreesStore from "./stores/TreesStore";
import SearchStore from "./stores/SearchStore";
import UiStore from "./stores/UiStore";
import Loading from "./common/Loading/Loading";
import { BugOutlined } from "@ant-design/icons";

const api = new Api();
const uiStore = new UiStore();
const treesStore = new TreesStore(api, uiStore);
const searchStore = new SearchStore(api);

const stores = {
  treesStore,
  searchStore,
  uiStore,
};

function App() {
  return (
    <Provider {...stores}>
      <BrowserRouter>
        {!treesStore.isInit ? (
          <Loading />
        ) : (
          <div className="App">
            <Header />
            <Middle />
            <Footer />
            <a
              href="https://docs.google.com/forms/d/1AE6Mw1oHl6PaNgio2Y9GoKLvU55OFVBQVh6ePhz1rAc/viewform?ts=60100a84&gxids=7628"
              target="blank"
              className="report-bug-button"
            >
              <BugOutlined />
            </a>
          </div>
        )}
      </BrowserRouter>
    </Provider>
  );
}

export default observer(App);
