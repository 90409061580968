import { Checkbox, Form } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps<any> {
  logo: any;
  textLabel: any;
  name: any;
  backgroundClass: any;
}

class SearchTypeCheckbox extends React.PureComponent<Props, any> {
  render() {
    const { name, textLabel, backgroundClass, logo } = this.props;
    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[name] !== currentValues[name]
        }
      >
        {({ getFieldValue }: any) => {
          return (
            <Form.Item name={name} valuePropName="checked">
              <Checkbox value={name}>
                <div
                  className={`gap10 flex btn ${backgroundClass} ${
                    getFieldValue(name) ? "active" : ""
                  }`}
                >
                  {logo}

                  <div style={{ lineHeight: 2 }} className="f18 fw400  ">
                    {textLabel}
                  </div>
                </div>
              </Checkbox>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
}
export default withRouter(SearchTypeCheckbox);
