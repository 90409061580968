import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./BookInfo.scss";

interface Props extends RouteComponentProps<any> {
  reliabilityLevel: string;
  editor: string;
  date: string;
  writeLocation: string;
  description: string;
  print: boolean;
  onPrint: () => void;
}

class BookInfo extends React.PureComponent<Props, any> {
  render() {
    const {
      reliabilityLevel,
      editor,
      date,
      writeLocation,
      description,
    } = this.props;
    return (
      <div className="BookInfo flex1">
        <div className="inner-wrapper padding20">
          {/* <div className="padding40">
            <img
              className="full"
              src="/images/book/2.png"
              alt="images/book/2.png"
            />
          </div> */}

          <div className="textCenter blueT marginTop20">
            {editor && (
              <div className="marginTop20">
                <div className="f18 bold">שם העורך</div>

                <div className="f18">{editor || " - "}</div>
              </div>
            )}
            {reliabilityLevel && (
              <div className="marginTop20">
                <div className="f18 bold">רמת עריכה</div>

                <div className="f18">{reliabilityLevel || " - "}</div>
              </div>
            )}
            {date && (
              <div className="marginTop20">
                <div className="f18 bold">תאריך</div>
                <div className="f18">{date || " - "}</div>
              </div>
            )}
            {writeLocation && (
              <div className="marginTop20">
                <div className="f18 bold">מיקום</div>
                <div className="f18">{writeLocation || " - "}</div>
              </div>
            )}
            {description && (
              <div className="marginTop20">
                <div className="f18 bold">תיאור</div>
                <div className="f18 justify">{description || " - "}</div>
              </div>
            )}
          </div>

          <div className="textCenter blueT marginTop20">
            {/* <div className="flex justifyEnd">
              <div
                className="f18 fw400 blueT  marginLeft20"
                style={{ margin: "8px" }}
              >
                <Tags tagsName={["מעניין", "מפתיע", "מעשיר"]} />
              </div>
              <img
                alt="s"
                className="fleft"
                width="15"
                style={{ margin: "13px" }}
                src="/images/tag.svg"
              />
            </div> */}

            {/* <div className="flex justifyEnd">
              <div
                className="f18 fw400 blueT  marginLeft20"
                style={{ margin: "8px" }}
              >
                204
              </div>
              <img
                className="fleft"
                width="15"
                style={{ margin: "13px" }}
                src="/images/contact/address.svg"
              />
            </div> */}

            {this.props.print ? (
              <div className="flex justifyCenter">
                <img
                  alt="print icon"
                  className="fleft"
                  width="15"
                  src="/images/printer.svg"
                />
                <div
                  onClick={this.props.onPrint}
                  className="f18 fw400 blueT  marginLeft20 pointer"
                  style={{ margin: "8px" }}
                >
                  הדפסה
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(observer(BookInfo));
