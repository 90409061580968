import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LeftSideBarStyled } from "./styled";
import LsContact from "./components/LsContact/LsContact";

interface Props extends RouteComponentProps<any> {}

class LeftSideBar extends React.PureComponent<Props, any> {
  render() {
    return (
      <LeftSideBarStyled>
        <LsContact />
      </LeftSideBarStyled>
    );
  }
}
export default withRouter(LeftSideBar);
