import styled from "styled-components";

const SearchPanelStyled: any = styled.div`
  max-width: ${({ hasResults }: any) => (hasResults ? "1000" : "612")}px;
  margin: 0 auto;
  width: 100%;

  .ant-checkbox {
    display: none !important;
  }

  .ant-row.ant-form-item {
    width: 100%;
  }
  .ant-checkbox-wrapper {
    display: flex;
    flex: 1;
    span {
      &:last-child {
        flex: 1;
      }
    }
  }

  .InputSearch {
    display: flex;
  }

  .file-type-options {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
  }

  @media only screen and (max-width: 600px) {
    .file-type-options {
      grid-template-columns: 1fr 1fr;
    }
    &.max-width-form {
      padding: 0 20px;
    }
    .flex.InputPanel.padding-mobile {
      padding-right: initial !important;
      padding-left: initial !important;
      padding-top: 0 !important;
    }

    .bold.marginHor40.f24 {
      margin: 15px auto !important;
    }
  }
  .input_search {
    min-width: 320px;
  }

  .search_text {
    line-height: 3;
    margin-right: 25px;
    text-decoration: underline;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 2px 30px;
    align-items: center;
    &:hover {
      box-shadow: 1px 1px 1px 1px #1d455fa6;
    }
    svg {
      max-width: 16px;
    }
  }

  .flex.btn {
    border: 1px solid;
    border-radius: 6px;
  }

  .btn.br-red {
    color: rgb(210, 97, 97);
    border-color: rgb(210, 97, 97);
    svg {
      fill: rgb(210, 97, 97);
    }
  }

  .btn.br-blue {
    color: #4380ac;
    border-color: #4380ac;
    svg {
      fill: #4380ac;
    }
  }

  .flex.btn.br-pirple {
    color: #a29ab2;
    border-color: #a29ab2;
    svg {
      fill: #a29ab2;
    }
  }

  .flex.btn.br-blue2 {
    color: rgb(124, 180, 212);
    border-color: rgb(124, 180, 212);
    svg {
      fill: rgb(124, 180, 212);
    }
  }

  .flex.btn.active {
    color: white;
    svg {
      fill: white;
    }
    &.br-blue2 {
      background: rgb(124, 180, 212);
    }
    &.br-pirple {
      background: #a29ab2;
    }
    &.br-blue {
      background: #4380ac;
    }
    &.br-red {
      background: rgb(210, 97, 97);
    }
  }

  .btn {
    padding: 0px;
  }

  .InputWrapper {
    margin: ${({ hasResults }: any) => (hasResults ? "0" : "0 auto")};
    max-width: 520px;
    input {
      border-radius: 0;
    }
    button {
      width: 46px;
      height: 44px;
      background-color: #68abcc;
    }
  }
  @media only screen and (max-width: 600px) {
    .flex.InputPanel.padding-mobile {
      flex-direction: column;
    }

    .input_search {
      min-width: 0px;
    }

    .InputSearch.InputWrapper {
      margin: 0;
    }
  }
`;

export { SearchPanelStyled };
