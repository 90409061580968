import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Drawer, Menu } from "antd";
import "./Header.scss";
import { observable, makeObservable } from "mobx";
import { ReactSVG } from "react-svg";
import { inject, observer } from "mobx-react";
import { ReactComponent as GalEnaiLogo } from "../../assets/images/mobile-menu.svg";
import SearchStore from "../../stores/SearchStore";

interface Props extends RouteComponentProps<any> {
  searchStore?: SearchStore;
}

class PageHeader extends React.PureComponent<Props, any> {
  visible: boolean = false;

  componentDidMount = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  showDrawer = () => {
    this.visible = true;
  };

  onClose = () => {
    this.visible = false;
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      visible: observable
    });
  }

  render() {
    return (
      <>
        <Row className="PageHeader header padding10 hide-mobile">
          <Col className="textLeft padding10" span={18}>
            <a
              className="br  circle pointer padding5 paddingVer20 fLeft margin5 donate"
              href="https://www.charidy.com/cmp/niflaot"
              target="_blank"
            >
              <b className="f18">היה שותף</b> תרום למכון לגל עיני
            </a>

            <Link
              className="f18 blue marginLeft40 link-header fLeft"
              to="/contact"
            >
              צור קשר
            </Link>

            <Link
              className="f18 blue marginLeft40 link-header fLeft"
              to="/newsletter"
            >
              הרשמה לניוזלטר
            </Link>

            <Link
              className="f18 blue marginLeft40 link-header fLeft"
              to="/need-help"
            >
              לעזרה בחיפוש מקורות
            </Link>

            <Link
              className="f18 blue marginLeft40 link-header fLeft"
              to="/search/advanced"
              onClick={() => {
                this.props.searchStore?.cleanResults();
                // this.props?.changMode("advanced");
              }}
            >
              חיפוש מתקדם
            </Link>

            <Link
              className="f18 blue marginLeft40 link-header fLeft"
              to="/about"
            >
              אודות
            </Link>

            <Link className="f18 blue marginLeft40 link-header fLeft" to="/">
              ראשי
            </Link>
          </Col>

          <Col span={6}>
            <Link to="/">
              <img className="fright" width="170" src="/svgs/logo.svg" />
            </Link>
          </Col>
        </Row>

        <div className="flex hide-desktop top-header-mobile">
          <Link to="/">
            <img
              alt="gal enai logo"
              className="fright top-header-mobile-logo"
              width="170"
              src="/svgs/logo.svg"
            />
          </Link>

          {!this.visible ? (
            <GalEnaiLogo className="menu_icon" onClick={this.showDrawer} />
          ) : (
            <GalEnaiLogo className="menu_icon" onClick={this.showDrawer} />
          )}
        </div>

        <Drawer
          title="תפריט"
          placement="left"
          className="menu-mobile"
          closable={false}
          onClose={this.onClose}
          visible={this.visible}
        >
          <Menu className="textRight marginBottom20">
            <Menu.Item>
              <Link to="/" className="f20 bold">
                <div className={"white"}>ראשי</div>
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/search" className=" f20 bold">
                <div className={"white"}>חיפוש מתקדם</div>
              </Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/" className=" f20 bold">
                <div className={"white"}>עיון</div>
              </Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/contact" className={"white f20 bold"}>
                <div className={"white"}>צור קשר</div>
              </Link>
            </Menu.Item>
          </Menu>

          <a
            className="marginTop60 br br-blue2 blue2 circle pointer padding5 paddingVer20  textCenter f20"
            href="http://galeyes.aminutapps.com/recurring/first.html?w=564979"
            target="_blank"
          >
            <b className="blue2 bold">היה שותף</b> תרום לגל עיני
          </a>

          <li className="marginTop40 padding">
            <div className="flex margin20">
              <img
                alt="3123"
                className="fright"
                width="15"
                src="/images/contact/phone.svg"
              />
              <div className="f4 fw400 white marginRight20 f18">
                02-657-6885
              </div>
            </div>

            <div className="flex margin20">
              <img
                alt="3123"
                className="fright"
                width="15"
                src="/images/contact/email.svg"
              />
              <div className="f4 fw400 white marginRight20 f18">
                info@sfarim.co.il
              </div>
            </div>

            <div className="flex margin20">
              <img
                alt="3123"
                className="fright"
                width="15"
                src="/images/contact/address.svg"
              />
              <div className="f4 fw400 white marginRight20 f18">
                רחוב דוד המלך 12, ירושלים
              </div>
            </div>
          </li>
        </Drawer>
      </>
    );
  }
}
export default inject("searchStore")(observer(withRouter(observer(PageHeader))));
