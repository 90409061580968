import { Pagination } from "antd";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SearchPaginationStyled } from "./styled";
import qs from "qs";
import { action, observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import SearchStore from "../../../../../../../stores/SearchStore";

interface Props extends RouteComponentProps<any> {
  searchStore?: SearchStore;
}

class SearchPagination extends React.PureComponent<Props, any> {
  currentPage: number | undefined;
  pageSize: number | undefined;

  setCurrentPage = (currentPage: number) => {
    this.currentPage = currentPage;
  };
  setPageSize = (pageSize: number) => {
    this.pageSize = pageSize;
  };

  componentDidMount = () => {
    const queryParams = this.getQueryParams();
    this.setCurrentPage(queryParams.currentPage);
    this.setPageSize(queryParams.pageSize);
  };

  getQueryParams = (): any => {
    return qs.parse(this.props.location.search.replace("?", ""), {
      decoder: function (value: any) {
        if (/^(\d+|\d*\.\d+)$/.test(value)) {
          return parseFloat(value);
        }

        let keywords: any = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
        };
        if (value in keywords) {
          return keywords[value];
        }

        return decodeURI(value);
      },
    });
  };

  onChangePageResultsSettings = (page: number) => {
    const searchParams = {
      ...this.getQueryParams(),
      currentPage: page,
    };

    this.props.history.push(`/search/advanced?${qs.stringify(searchParams)}`);
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      currentPage: observable,
      pageSize: observable,
      setCurrentPage: action,
      setPageSize: action,
      onRouteChanged: action
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const searchParams = this.getQueryParams();
    this.setCurrentPage(searchParams.currentPage);
    this.setPageSize(searchParams.pageSize);
  }

  render() {
    return (
      <SearchPaginationStyled>
        <Pagination
          showSizeChanger={false}
          defaultPageSize={this.pageSize}
          current={this.currentPage}
          total={this.props.searchStore?.totalResults}
          onChange={this.onChangePageResultsSettings}
        />
      </SearchPaginationStyled>
    );
  }
}
export default withRouter(inject("searchStore")(observer(SearchPagination)));
