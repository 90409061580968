import styled from "styled-components";

const LsContactStyled = styled.div`
  background: url(/images/contact/contact-img.png), #1d455f;
  flex: 1;
  background-repeat: no-repeat;
  height: 100vh;
`;

export { LsContactStyled };
