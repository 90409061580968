const debounce = (func: Function, delay: number = 450) => {
  let inDebounce: any;
  return (...args: any): any => {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default debounce;
