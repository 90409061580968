import { Input } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SearchStore from "../../../../../../../stores/SearchStore";
import SearchPanel, {
  formMode,
} from "../../../PcSearch/components/SearchPanel/SearchPanel";
import "./HeaderSearch.scss";
const { Search } = Input;
interface Props extends RouteComponentProps<any> {
  searchStore?: SearchStore;
}

class HeaderSearch extends React.PureComponent<Props, any> {
  initialValues: any = {
    books: true,
    subjects: true,
    audio: true,
    lessons: true,
    searchMethod: "2",
    searchType: "1",
  };
  render() {
    return (
      <div className="HeaderSearch b-blueT white padding40">
        <div
          className="padding40 no-padding-mobile marginCenter"
          style={{ maxWidth: 600 }}
        >
          <div className="f50 f22-mobile bold title">
            מאגר מידע וחיפוש ספרים
          </div>
          <SearchPanel
            isHomepage={true}
            initialValues={this.initialValues}
            mode={formMode.regular}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(inject("searchStore")(observer(HeaderSearch)));
