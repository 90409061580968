import styled from "styled-components";

const PcBookStyled = styled.div`
  position: relative;
  /* z-index: 31; */
  .book-content-wrapper {
    text-align: justify;
    padding: 0 60px;
    max-width: 850px;
    width: 100%;
    flex: 1;
  }

  .back-to-top {
    position: fixed;
    bottom: 40px;
    left: 40px;
    z-index: 100;
  }
`;

export { PcBookStyled };
