import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { CategoriesStyled, CategoryItemStyled } from "./styled";
import { observer } from "mobx-react";

interface Props extends RouteComponentProps<any> {
  items: any[];
  colors: any[];
}

class Categories extends React.PureComponent<Props, any> {
  getBackgroundColor = () => this.props.colors[Math.floor(Math.random() * 5)];
  render() {
    return (
      <CategoriesStyled>
        {this.props.items.map((item: any, index: number) => (
          <Link
            key={item.uuid}
            to={item.route}
            className="category-box-content"
          >
            <CategoryItemStyled
              className="content"
              style={{ backgroundColor: this.getBackgroundColor() }}
            >
              <span>{item.text}</span>
              <span>
                {item.childrenCount !== null ? `(${item.childrenCount})` : ""}
              </span>
            </CategoryItemStyled>
          </Link>
        ))}
      </CategoriesStyled>
    );
  }
}
export default withRouter(observer(Categories));
