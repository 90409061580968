import styled from "styled-components";
import { CommonCategoryBox } from "../../../../../../styled";

const CategoriesStyled = styled(CommonCategoryBox)``;
const CategoryItemStyled = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 10px;

  span:first-child {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 26px;
  }
  span:last-child {
    font-size: 18px;
    color: #ffffffab;
  }

  @media only screen and (max-width: 640px) {
    span:first-child {
      font-size: 19px;
      line-height: 24px;
    }
    span:last-child {
      font-size: 15px;
      line-height: 14px;
    }
  }
`;

export { CategoriesStyled, CategoryItemStyled };
