import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PcSearchStyled } from "./styled";
import SearchPanel, { formMode } from "./components/SearchPanel/SearchPanel";
import SearchResults from "./components/SearchResults/SearchResults";
import { inject, observer } from "mobx-react";
import UiStore from "../../../../../stores/UiStore";
import { Divider, Pagination } from "antd";
import SearchStore from "../../../../../stores/SearchStore";
import { action, observable, makeObservable } from "mobx";
import qs from "qs";
import SearchPagination from "./components/SearchPagination/SearchPagination";
interface Props extends RouteComponentProps<any> {
  uiStore?: UiStore;
  searchStore?: SearchStore;
}

class PcSearch extends React.PureComponent<Props, any> {
  initialValues: any = {
    books: true,
    subjects: true,
    audio: true,
    lessons: true,
    searchMethod: "2",
    searchType: "1",
  };

  componentDidMount = () => {
    this.props!.uiStore!.breadcrumbs = [
      { path: "/", text: "ראשי" },
      { path: "/search", text: "חיפוש" },
    ];
  };

  urlHasAdvancedMode = () => {
    return this.props.location.pathname.includes("advanced");
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      componentDidMount: action
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <PcSearchStyled>
        {this.props.searchStore?.loading && (
          <div className="loading-mask">
            <img src="/images/loading-0.8s-207px.svg" alt="loading" />
          </div>
        )}
        <SearchPanel
          initialValues={this.initialValues}
          mode={formMode.advanced}
        />

        {this.props!.searchStore!.results?.length > 0 && (
          <>
            <Divider className="noMargin" />

            {/* {this.mode === "regular" ? ( */}
            <SearchResults
              totalResults={this.props!.searchStore!.totalResults}
              query={this.props!.searchStore!.query}
              results={this.props!.searchStore!.results}
            />

            <SearchPagination />
          </>
        )}
      </PcSearchStyled>
    );
  }
}
export default withRouter(inject("uiStore", "searchStore")(observer(PcSearch)));
