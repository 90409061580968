import styled from "styled-components";

const BookMediaStyled = styled.div`
  .plyr {
    max-width: 600px !important;
    width: 100%;
    border: 1px solid #1e455f;
    border-radius: 5px;
  }
  .plyr__progress input[type="range"] {
    color: #1e455f;
  }
  .plyr--audio .plyr__progress__buffer {
    background: #1b6f9e !important;
  }
  .anticon.anticon-sound {
    transform: rotateZ(180deg);
  }
  .media-link-item {
    font-size: 18px;
    border-bottom: 1px solid #d0d0d0;
    padding: 10px 0;
    cursor: pointer;
    &.current {
      font-weight: bold;
    }
    :last-child {
      border-bottom-color: transparent;
    }
  }

  .download-button {
    background-color: #1d455f;
    color: white;
    margin-right: 10px;
    border-radius: 5px;
  }
`;

export { BookMediaStyled };
