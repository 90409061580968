import { Modal } from "antd";
import { action, observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BookContentStyled } from "./styled";
interface Props extends RouteComponentProps<any> {
  loading: boolean;
  html: string;
  currentNode: any;
}

class BookContent extends React.PureComponent<Props, any> {
  tooltipRef: any;
  htmlWrapperRef: any;
  visible: boolean = false;
  tooltipHtml: string = "default text";
  htmlWrapperTop: number = 0;

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      visible: observable,
      tooltipHtml: observable,
      onclick: action
    });

    this.tooltipRef = React.createRef();
    this.htmlWrapperRef = React.createRef();
  }

  componentDidMount = () => {
    this.htmlWrapperTop = this.getPosition(this.htmlWrapperRef.current).top;
  };

  getPosition = (element: any) => {
    const top = element.offsetTop;

    return { top };
  };

  openTootTip = () => {
    this.visible = true;
  };

  closeTootTip = () => {
    this.visible = false;
  };

  onclick = (event: any) => {
    document.querySelectorAll(".tooltip-small").forEach((e) => e.remove());
    const target = event.target;
    if (!target.closest("a")) return;

    const link = target.closest("a");

    if (!link.href.includes("#_")) {
      return;
    }
    const { top } = this.getPosition(link);

    const [, noteKey] = link.href.split("#");
    const content = this.props.currentNode.footNotes[noteKey];

    let htmlObject = document.createElement("div");
    htmlObject.setAttribute("class", "tooltip-small");
    htmlObject.innerHTML = content;
    let arrow = document.createElement(`div`);
    arrow.setAttribute("class", "triangle-up");
    htmlObject.appendChild(arrow);

    let contentLength = htmlObject.innerText.length;

    if (contentLength > 50) {
      this.tooltipHtml = content;
      this.visible = true;
    } else {
      link.appendChild(htmlObject);
      // this.tooltipHtml = content;
      // this.tooltipRef.current.style.top = `${top}px`;
    }

    event.preventDefault();
    return false;
  };

  render() {
    let { loading, html } = this.props;

    return (
      <BookContentStyled onClick={this.onclick} ref={this.htmlWrapperRef}>
        {loading ? (
          <img src="/images/loading-0.8s-207px.svg" alt="loading" />
        ) : (
          <>
            <Modal
              className="wrap-pop-tooltip radius"
              title=""
              visible={this.visible}
              footer={false}
              // ref={this.popRef}
              onCancel={() => this.closeTootTip()}
            >
              <div
                className="pop-tooltip"
                dangerouslySetInnerHTML={{
                  __html: this.tooltipHtml,
                }}
              />

              <div
                className="btn b-blueT textCenter white padding20 paddingVer20 marginHor20 radius pointer"
                onClick={() => this.closeTootTip()}
              >
                חזרה
              </div>
            </Modal>

            <div
              className={`${
                this.props?.currentNode?.details?.language === "אנגלית"
                  ? "ltr"
                  : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </>
          // _html
        )}
      </BookContentStyled>
    );
  }
}
export default withRouter(observer(BookContent));
