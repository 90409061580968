import styled from "styled-components";

const LoadingStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  img.margin50 {
    max-width: 60px;
  }
`;

export { LoadingStyled };
