import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TreesStore from "../../../../../../../stores/TreesStore";
import Box from "../../../../../../Box/Box";
import { CommonCategoryBox } from "../../../../../../styled";
import "./New.scss";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}

class New extends React.PureComponent<Props, any> {
  itemColorConverter = (index: number) => {
    console.log(index);
    switch (index) {
      case 0:
        return "#308cc6";
        break;

      case 1:
        return "#927fb5";
        break;

      case 2:
        return "#ee9293";
        break;

      case 3:
        return "#68abcc";
        break;
    }
    return "#927fb5";
  };

  render() {
    const { treesStore } = this.props;
    const { children } = treesStore?.trees.news;
    console.log("news", children);

    return (
      <div className="New padding60 ">
        <div className="f2 blue bold marginHor20">חדש באתר</div>

        <CommonCategoryBox className="justifyCenter maxWidthCategories">
          {children.map((item: any, index: number) => (
            <Box
              key={1}
              boxType={"center"}
              backgroundHover={this.itemColorConverter(index)}
              action={["book"]}
              icon="/images/categories/classes-icon-white.svg"
              link={item.route}
              title={item.text}
              author={" שם יוצר "}
            />
          ))}
          {/* <Box
            key={2}
            boxType={"center"}
            backgroundHover="#927fb5"
            title="שם הספר/עיון"
            author="שם העורך"
            action={["video", "audio", "book"]}
            icon="/images/categories/subjects-white.svg"
          />
          <Box
            key={3}
            boxType={"center"}
            backgroundHover="#ee9293"
            title="שם הספר/עיון"
            author="שם העורך"
            action={["audio"]}
            icon="/images/categories/books-white.svg"
          />

          <Box
            key={4}
            boxType={"center"}
            backgroundHover="#308cc6"
            title="שם הספר/עיון"
            author="שם העורך"
            action={["video", "audio"]}
            icon="/images/categories/audio-white.svg"
          /> */}
        </CommonCategoryBox>

        <div className="f18 marginTop40 underLine blue2 pointer">הצג עוד</div>
      </div>
    );
  }
}
export default withRouter(inject("treesStore")(observer(New)));
