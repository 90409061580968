import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Recommended from "./components/Recommended/Recommended";
import { RsHomePageStyled } from "./styled";

interface Props extends RouteComponentProps<any> {}

class RsHomePage extends React.PureComponent<Props, any> {
  render() {
    return (
      <RsHomePageStyled>
        <div className="f25 blue textRight bold">
          <div>מכון גל עיני</div>
          תורת הרב יצחק גינזבורג שליט"א
        </div>
        <div className="f22 blue textRight marginTop20 about-text ">
          <p>
            האור שבפנימיות התורה, "תורה אור", הוא הגאולה והתרופה לעולם. במשך
            למעלה מ-40 שנה מלמד הרב גינזבורג את פנימיות התורה - באלפי שיעורים,
            ומאות מאמרים וספרים בעברית ובאנגלית, בכל קצווי הארץ והעולם, לגברים
            ולנשים, לקהלים שונים ומגוונים, ליהודים וגם לבני-נח.
          </p>

          <p>
            השפה בה משתמש הרב גינזבורג היא שפת הקבלה והחסידות, בדגש על חסידות
            חב"ד, אך בין השיטין נבנה כאן עולם מושגים ייחודי, שפה חדשה ודרך לימוד
            והתבוננות מיוחדת. תורת הרב גינזבורג כוללת עושר עצום. היא מביאה אור
            פנימיות התורה לכל תחומי החיים - מהשוואה בין מושגי הקבלה המופשטים
            לתנועות הנפש הדקות והעדינות עד להתבוננות בענייני הציבור תיקון
            המדינה; מלימוד מעמיק בספרי הקבלה וחסידות עד להתבוננות בניצוץ האלוקי
            המתגלה בכל תחומי המדע; מגילוי הסודות הטמונים בעומק הפשט במקרא ועד
            לחשיפת המבנים הפנימיים שביסוד סוגיות הגמרא וההלכה; מהעמקה בעבודת
            התפלה בדורנו ועד לתובנות המעמיקות בכל הרבדים של חיי הנישואין; ועוד
            תחומים נוספים רבים. הספרים, והשיעורים הניחו תשתית, פתחו כיוונים,
            יצרו מושגים.
          </p>

          <p>
            אך נדרשת מאיתנו עבודה עצומה של העמקה, לימוד, מחקר, סידור, הנגשה,
            כתיבה, הוראה והסברה כדי שהתורות הנפלאות הללו יאירו את המציאות כולה
            באור פנימיות התורה, באופן השווה לכל נפש.
          </p>
          <div>
            זה האתגר שלפנינו.
            <br />
            מוזמנים להעמיק ולהצטרף!
          </div>
        </div>

        <div className="hide-mobile">
          <Recommended />
        </div>
      </RsHomePageStyled>
    );
  }
}
export default withRouter(RsHomePage);
