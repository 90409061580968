import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LoadingStyled } from "./styled";

interface Props extends RouteComponentProps<any> {}

class Loading extends React.PureComponent<Props, any> {
  render() {
    return (
      <LoadingStyled>
        <img
          src="/images/loading-0.8s-207px.svg"
          alt="loading"
          className="margin50"
        />
        <img className="fright" width="170" src="/svgs/logo.svg" alt="logo" />
      </LoadingStyled>
    );
  }
}
export default withRouter(Loading);
