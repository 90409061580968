import styled from "styled-components";

const CategoriesListStyled = styled.div`
  a {
    border-bottom: solid 0.5px #1d455f;
    display: block;
    &:last-child {
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }

    .f18.bold {
      display: block;
    }
    @media only screen and (max-width: 1000px) {
      .f18.bold {
        line-height: 19px;
        font-size: 17px !important;
      }
    }
  }

  .paddingHor12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const CategoryListItemStyled = styled.span`
  display: flex;
  align-items: center;
  color: #1d455f;
  /* font-weight: bold;
  font-size: 18px; */
  text-align: right;

  .icon {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 20px;
    img {
      max-width: 18px;
    }
  }

  .entry {
    font-size: 18px;
    font-weight: 600;
    color: white;
  }
`;
export { CategoriesListStyled, CategoryListItemStyled };
