import styled from "styled-components";

const PcCategoriesStyled: any = styled.div`
  max-width: ${({ fullWidth }: any) => (fullWidth ? "auto" : "878px")};
  margin: 60px auto;
  width: 100%;
  padding: 0 30px;

  .displayModeButton {
    padding: 5px;
    border-radius: 5px;
    margin: 0 5px;
    opacity: 0.5;

    &.selected {
      transform: scale(1.07);
      border-color: #1d455f;
      opacity: 1;
    }
  }
  .icons-display-mode {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    span:not(.selected) {
      opacity: 0.5;
    }
    span {
      border: 1px solid #000;
      padding: 5px;
      border-radius: 5px;
      margin: 0 7.5px;
    }
  }

  .title-and-icons {
    margin: 0 auto 20px;
  }

  .subject-description {
    text-align: right;
    margin-top: 30px !important;
  }

  @media only screen and (max-width: 1000px) {
    margin: 30px auto;
    padding: 0 10px;
    .icons-display-mode {
      justify-content: flex-end;
    }

    .subject-description {
      margin: 20px auto !important;
      .f18 {
        font-size: 15px !important;
        line-height: 20px;
      }
    }
  }
`;
const H2Styled = styled.h2`
  font-size: 24px;
  color: ${(props: any) => props.theme.blue};
  text-align: center;
  font-weight: bold;
`;

export { PcCategoriesStyled, H2Styled };
