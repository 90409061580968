import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { BoxStyled } from "./styled";

export type boxType = "image" | "center";

interface Props extends RouteComponentProps<any> {
  boxType: boxType;
  linkTo?: any;
  image?: string;
  backgroundHover: string;
  title: string;
  total?: string;
  icon?: string;
  author?: string;
  action?: Array<string>;
  className?: any;
  link: string;
}

class Box extends React.PureComponent<Props, any> {
  render() {
    let {
      boxType,
      linkTo,
      image,
      backgroundHover,
      title,
      icon,
      total,
      author,
      action,
      link,
      className,
    } = this.props;
    return (
      <BoxStyled>
        <Link to={link} style={{ position: "relative" }}>
          {boxType === "image" ? (
            <Link to={linkTo}>
              <div
                className={`box padding10 pointer ${className}`}
                style={{
                  backgroundColor: backgroundHover,
                  backgroundImage: `url(${image})`,
                  borderRadius: "13px",
                }}
              >
                <div className="layer" />
              </div>
              <div className="textRight white float-text">
                <div className="f30 bold">{title}</div>
                <div className="f18">({total}) </div>
              </div>
            </Link>
          ) : (
            <div
              className={`box padding10 pointer white ${className}`}
              style={{
                backgroundColor: backgroundHover,
                borderRadius: "13px",
              }}
            >
              {/* <img
                className="marginHor20"
                width="20"
                src={icon}
                alt="video-icon-white"
              /> */}
              <div className="f2 f16-mobile bold title-book">{title}</div>
              <div className="f4">{author} </div>

              <div className="flex action justifyCenter marginTop20">
                {action !== undefined &&
                  action.map(function (item, i) {
                    return (
                      <div>
                        {item === "video" ? (
                          <img
                            alt="video-icon-white"
                            className="margin10"
                            width="15"
                            src={"/images/Book-type/video/video-icon-white.svg"}
                          />
                        ) : null}

                        {item === "audio" ? (
                          <img
                            alt="video-icon-white"
                            className="margin10"
                            width="15"
                            src={"images/Book-type/Audio/audio-icon-white.svg"}
                          />
                        ) : null}

                        {item === "book" ? (
                          <img
                            alt="video-icon-white"
                            className="margin10"
                            width="15"
                            src={"images/Book-type/text/text-icon-white.svg"}
                          />
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Link>
      </BoxStyled>
    );
  }
}
export default withRouter(Box);
