import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { PcCategoriesStyled, H2Styled } from "./styled";
import Categories from "./components/Categories/Categories";
import { observer, inject } from "mobx-react";
import TreesStore from "../../../../../stores/TreesStore";
import { ColumnWidthOutlined } from "@ant-design/icons";
import { observable, action, makeObservable } from "mobx";
import CategoriesList from "./components/CategoriesList/CategoriesList";
import { Select, Button } from "antd";
import { ReactComponent as GridIcon } from "../../../../../assets/images/grid-mode.svg";
import { ReactComponent as ListIcon } from "../../../../../assets/images/list-mode.svg";

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}

class PcCategories extends React.PureComponent<Props, any> {
  displayMode: string = "list";
  displayFullWidthMode: boolean = false;

  setDisplayMode = (mode: "list" | "box") => {
    if (mode !== "box") {
      this.displayFullWidthMode = false;
    }
    this.displayMode = mode;
    localStorage.setItem("displayMode", mode);
  };

  componentDidMount = () => {
    this.displayMode = localStorage.getItem("displayMode") || "box";
    window.scrollTo(0, 0);
  };

  redirectToTree = (treeSlug: string) => {
    const { history } = this.props;
    history.push(`/${treeSlug}`);
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      displayMode: observable,
      displayFullWidthMode: observable,
      setDisplayMode: action
    });
  }

  render() {
    const { treesStore } = this.props;
    const title = this.props.treesStore!.currentNode.tocHeader;
    const subTitle = this.props.treesStore!.currentNode.tocExplain;
    const bookLinkId = this.props.treesStore!.currentNode.bookLinkId;
    const bookLinkName = this.props.treesStore!.currentNode.bookLinkName;
    const tocLinkId = this.props.treesStore!.currentNode.tocLinkId;
    const treeLinks = this.props.treesStore!.treeLinks;

    // console.log({
    //   component: "render <PcCategories/>",
    //   currentNode: JSON.parse(
    //     JSON.stringify(this.props.treesStore!.currentNode)
    //   ),
    // });

    let node = treesStore!.findDeep(
      treesStore!.trees!.books!.children,
      bookLinkId,
      "bookLinkId"
    );
    if (!node) {
      node = treesStore!.findDeep(
        treesStore!.trees!.lessons!.children,
        bookLinkId,
        "bookLinkId"
      );
    }

    const href = node ? `${node.route}${tocLinkId}` : "";

    console.log({
      title,
      subTitle,
      href,
      bookLinkId,
      bookLinkName,
      tocLinkId,
      treeLinks,
      currentNode: JSON.parse(JSON.stringify(treesStore!.currentNode)),
    });

    return (
      <PcCategoriesStyled
        className="flex1"
        fullWidth={this.displayFullWidthMode}
      >
        <div className="textRight flex title-and-icons">
          <div className="flex">
            <H2Styled
              className="hide-mobile"
              style={{ textAlign: "right", marginBottom: 0 }}
            >
              {treesStore?.currentTree!.name}
            </H2Styled>
            <Select
              className="hide-desktop"
              defaultValue={treesStore!.currentNode!.slug}
              style={{ width: 120 }}
              onChange={(treeSlug: string) => this.redirectToTree(treeSlug)}
            >
              <Option value="books">
                {treesStore?.trees.books!.name} (
                {treesStore?.trees.books!.name === treesStore?.currentTree!.name
                  ? treesStore!.currentNode.childrenCount ||
                    treesStore!.trees.books!.itemsCount
                  : treesStore!.trees.books!.itemsCount}
                )
              </Option>
              <Option value="audio">
                {treesStore?.trees.audio!.name} (
                {treesStore?.trees.audio!.name === treesStore?.currentTree!.name
                  ? treesStore!.currentNode.childrenCount ||
                    treesStore!.trees.audio!.itemsCount
                  : treesStore!.trees.audio!.itemsCount}
                )
              </Option>
              <Option value="subjects">
                {treesStore?.trees.subjects!.name} (
                {treesStore?.trees.subjects!.name ===
                treesStore?.currentTree!.name
                  ? treesStore!.currentNode.childrenCount ||
                    treesStore!.trees.subjects!.itemsCount
                  : treesStore!.trees.subjects!.itemsCount}
                )
              </Option>
              <Option value="lessons">
                {treesStore?.trees.lessons!.name} (
                {treesStore?.trees.lessons!.name ===
                treesStore?.currentTree!.name
                  ? treesStore!.currentNode.childrenCount ||
                    treesStore!.trees.lessons!.itemsCount
                  : treesStore!.trees.lessons!.itemsCount}
                )
              </Option>
            </Select>
            <div className="f18 marginRight10 hide-mobile">
              (
              {treesStore!.currentNode.childrenCount ||
                treesStore!.currentTree!.itemsCount}
              )
            </div>
          </div>

          <div className="icons-display-mode">
            <Button
              className={`displayModeButton ${
                this.displayMode === "list" ? "selected" : ""
              }`}
              onClick={() => this.setDisplayMode("list")}
            >
              <ListIcon />
            </Button>
            <Button
              className={`displayModeButton ${
                this.displayMode === "box" ? "selected" : ""
              }`}
              onClick={() => this.setDisplayMode("box")}
            >
              <GridIcon />
            </Button>
            {this.displayMode === "box" && (
              <ColumnWidthOutlined
                className={`${this.displayFullWidthMode ? "selected" : ""}`}
                onClick={() =>
                  (this.displayFullWidthMode = !this.displayFullWidthMode)
                }
              />
            )}
          </div>
        </div>

        {(title || subTitle) && (
          <div className="subject-description marginHor20">
            <div className="bold f20">{title}</div>
            <div className="f18">{subTitle}</div>
          </div>
        )}

        {treeLinks && (
          <div className="subject-description marginHor20">
            <div className="bold f20">קישורים נוספים</div>
            עץ : {treeLinks?.linkDetails?.treeName}
            <Link
              to={`/${treesStore?.getTreeSlug(
                treeLinks?.linkDetails?.treeId
              )}/${treeLinks?.linkDetails?.tocId}`}
            >
              {treeLinks?.linkDetails?.tocName}
            </Link>
          </div>
        )}

        {(bookLinkId || bookLinkName) && (
          <div className="subject-description marginHor20">
            <div className="bold f20">קישורים נוספים</div>
            עץ : {treeLinks?.linkDetails?.treeName}
            <Link to={`${href}`}>{bookLinkName}</Link>
          </div>
        )}

        {this.displayMode === "box" &&
        treesStore!.currentNode?.viewType !== 4 ? (
          <Categories
            colors={treesStore!.currentTree!.colors}
            items={treesStore!.currentNode?.children || []}
          />
        ) : (
          <CategoriesList
            colors={treesStore!.currentTree!.colors}
            items={treesStore!.currentNode?.children || []}
          />
        )}
      </PcCategoriesStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(PcCategories)));
