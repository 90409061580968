import styled from "styled-components";

const SearchResultsStyled: any = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;

  .content-link:hover {
    background-color: #d55759 !important;
  }

  @media only screen and (max-width: 1000px) {
    .mobile-tags .blueT {
      margin: 8px 16px 1px 0px;
      margin-right: 46px !important;
    }

    .padding20.no-padding-mobile {
      margin-top: 20px;
    }
  }
`;

export { SearchResultsStyled };
