import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
} from "react-router-dom";
import { RightSideBarStyled } from "./styled";
import RsHomePage from "./components/RsHomePage/RsHomePage";
import { observer, inject } from "mobx-react";
import CategoriesOrToc from "./components/CategoriesOrToc/CategoriesOrToc";
import TreesStore from "../../../stores/TreesStore";
import UiStore from "../../../stores/UiStore";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
  uiStore?: UiStore;
}
class RightSideBar extends React.PureComponent<Props, any> {
  render() {
    const { treesStore, uiStore } = this.props;
    return treesStore?.currentTreeIsAudio &&
      !treesStore?.currentNodeIsCategory ? null : (
      <RightSideBarStyled
        className={`flex shadow hide-mobile ${
          uiStore!.displayTableOfContent ? "responsive-current" : ""
        }`}
      >
        <Button
          className="close-button"
          onClick={() =>
            (uiStore!.displayTableOfContent = !uiStore?.displayTableOfContent)
          }
        >
          <CloseOutlined />
        </Button>
        <Switch>
          <Route exact path={"/"} component={RsHomePage} />

          <Route
            path={[
              "/books/:id/:chapter",
              "/books/:id",
              "/books",

              "/lessons/:id/:chapter",
              "/lessons/:id",
              "/lessons",

              "/subjects/:id/:chapter",
              "/subjects/:id",
              "/subjects",

              "/audio/:id/:chapter",
              "/audio/:id",
              "/audio",
            ]}
            component={CategoriesOrToc}
          />
          {/* <Route path={"/:treeName/:node"} component={RsTableOfContent} /> */}
        </Switch>
      </RightSideBarStyled>
    );
  }
}
export default withRouter(inject("treesStore", "uiStore")(observer(RightSideBar)));
