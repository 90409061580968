import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { CategoriesListStyled, CategoryListItemStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  items: any[];
  colors: any[];
}

class CategoriesList extends React.PureComponent<Props, any> {
  getBackgroundColor = () => this.props.colors[Math.floor(Math.random() * 5)];
  render() {
    return (
      <CategoriesListStyled>
        {this.props.items.map((item: any, index: number) => (
          <Link key={item.uuid} to={item.route} className="paddingHor12">
            <CategoryListItemStyled>
              <span
                style={{ backgroundColor: this.getBackgroundColor() }}
                className="icon"
              >
                {item?.viewType !== 4 && (
                  <img
                    src="/images/Book-type/text/text-icon-white.svg"
                    alt="text-icon-white.svg"
                  />
                )}
                {item?.viewType === 4 && <span className="entry">ע</span>}
              </span>
              <span>
                <span className="f18 bold">{item.text}</span>
                {/* {item?.parent?.viewType === 4 && <span>{item.tocHeader}</span>} */}
                {item?.parent?.viewType === 4 && <span>{item.tocExplain}</span>}
                {item?.parent?.viewType !== 4 && (
                  <span>
                    {item.childrenCount !== null
                      ? `(${item.childrenCount})`
                      : ""}
                  </span>
                )}
              </span>
            </CategoryListItemStyled>
          </Link>
        ))}
      </CategoriesListStyled>
    );
  }
}
export default withRouter(CategoriesList);
