import styled from "styled-components";

const RightMenuTitleTitleStyled = styled.div`
  color: #c6c6c6;
  font-size: 24px;
  font-weight: bold;
  /* border-bottom: 1px solid ${(props) => props.theme.blue}; */
  border-bottom: 1px solid #1d455f;

  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

const CommonCategoryBox = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  width: 100%;
  /* padding: 0 30px; */
  /* max-width: 878px; */
  .category-box-content {
    position: relative;
    :after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  @media only screen and (max-width: 640px) {
    padding: 0px;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 450px) {
    padding: 0px;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
`;
export { RightMenuTitleTitleStyled, CommonCategoryBox };
