import styled from "styled-components";

const SearchPaginationStyled = styled.div`
  /* .ant-pagination { */

  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px 0;
  border-top: 1px solid #1d455f;
  /* } */
  .ant-pagination-item-active {
    border-color: #1d455f;
    background-color: #1d455f;
    a {
      color: white;
    }
  }
`;

export { SearchPaginationStyled };
