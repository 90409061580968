import styled from "styled-components";

const PcNeedHelpStyled = styled.div`
  .search_container {
    max-width: 600px;
  }

  label {
    color: #246f9e !important;
    font-size: 21px !important;
    text-align: right !important;
    float: right;
  }

  .ant-col.ant-form-item-control {
    width: 100% !important;
    flex: auto;
  }

  button.ant-btn.b-blueT {
    width: 100%;
    height: 48px;
  }

  .message_green {
    color: #48b60a !important;
    text-align: center;
    font-size: 20px;
  }
  @media only screen and (max-width: 560px) {
    .flex {
      flex-direction: column;
    }

    .flex .half {
      margin: 15px 0px !important;
      width: 100% !important;
    }
  }
`;

export { PcNeedHelpStyled };
