import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./Recommended.scss";
import Recommend from "./Recommend/Recommend";
import TreesStore from "../../../../../../../stores/TreesStore";
import { observer, inject } from "mobx-react";

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
}
class Recommended extends React.PureComponent<Props, any> {
  render() {
    const { treesStore } = this.props;
    const { children } = treesStore?.trees.recommended;
    console.log("רקבםצקמגקג", children);
    return (
      <div className="marginTop40">
        <div className="f24 blue textRight br-bottom br-blue paddingHor20">
          {" "}
          מומלצים
        </div>

        {children.map((item: any, index: number) => (
          <Recommend
            key={1}
            likes={204}
            title={item.text}
            link={item.route}
            description="שם העורך כאן"
            image={"/images/book/1.png"}
            action={["video", "audio", "book"]}
          />
        ))}
      </div>
    );
  }
}
export default withRouter(inject("treesStore")(observer(Recommended)));
