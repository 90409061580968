import styled from "styled-components";

const CategoryBoxStyled = styled.div`
  /* width: 200px;
  height: 200px; */
  border-radius: 15px;
  display: flex;
  &:hover {
    transform: scale(1.06);
  }
  a {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    color: white;

    span:last-child {
      color: #ffffffab;
    }
  }

  .icon_box_1 {
    position: absolute;
    top: 20px;
    right: 16px;
  }
`;

export { CategoryBoxStyled };
