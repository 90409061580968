import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BookHeaderStyled } from "./styled";
import { inject, observer } from "mobx-react";
import TreesStore from "../../../../../../../stores/TreesStore";
import { ITabOptions } from "../../PcBook";
import { Button } from "antd";
import UiStore from "../../../../../../../stores/UiStore";
import { observable, makeObservable } from "mobx";

type media = {
  id: string;
  title: string;
};

type mediaLink = {
  tocId: string;
  media: media[];
};

interface Props extends RouteComponentProps<any> {
  treesStore?: TreesStore;
  hasMediaLinks: boolean;
  hasTreeLinks: boolean;
  setCurrentTab: (currentTab: ITabOptions) => void;
  currentTab: ITabOptions;
  title: string;
  subTitle: string;
  uiStore?: UiStore;
}

class BookHeader extends React.PureComponent<Props, any> {
  contentType: string = "";
  componentDidMount = () => {
    try {
      if (window.location.href.indexOf("audio") > -1) {
        this.contentType = "audio";
      }
    } catch (error) {}
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      contentType: observable
    });
  }

  render() {
    const {
      hasMediaLinks,
      hasTreeLinks,
      setCurrentTab,
      currentTab,
      title,
      subTitle,
    } = this.props;

    return (
      <BookHeaderStyled className="b-blueT marginCenter  white  textRight BookHeader padding-mobile no-padding-bottom">
        <div className="">
          <div className="f24">{title}</div>
          <div className="f18 marginBottom40 marginHor10">{subTitle}</div>

          <div className="button-group">
            {this.contentType !== "audio" ? (
              <div
                className={`${currentTab === "content" ? "active" : ""} btn`}
                onClick={() => setCurrentTab("content")}
              >
                <img
                  alt="123"
                  className="fright"
                  width="18"
                  src="/images/contact/address.svg"
                />

                <div
                  style={{ lineHeight: 2 }}
                  className="f18 fw400  marginRight20"
                >
                  טקסט
                </div>
              </div>
            ) : null}

            {hasMediaLinks && (
              <div
                className={`${currentTab === "audio" ? "active" : ""} btn`}
                onClick={() => setCurrentTab("audio")}
              >
                <img
                  alt="123"
                  className="fright"
                  width="18"
                  src="/images/Book-type/Audio/audio-icon-white.svg"
                />
                <div
                  style={{ lineHeight: 2 }}
                  className="f18 fw400  marginRight20"
                >
                  אודיו
                </div>
              </div>
            )}

            {hasTreeLinks && (
              <div
                className={`${currentTab === "treeLinks" ? "active" : ""} btn`}
                onClick={() => setCurrentTab("treeLinks")}
              >
                <img
                  alt="123"
                  className="fright"
                  width="18"
                  src="/images/contact/address.svg"
                />
                <div
                  style={{ lineHeight: 2 }}
                  className="f18 fw400  marginRight20"
                >
                  קישורים נוספים
                </div>
              </div>
            )}
          </div>
        </div>
      </BookHeaderStyled>
    );
  }
}
export default withRouter(inject("treesStore", "uiStore")(observer(BookHeader)));
