import { action, observable, makeObservable } from "mobx";

export type route = {
  path: string;
  text: string;
};

class UiStore {
  breadcrumbs: route[] = [];
  displayTableOfContent: boolean = false;

  toggleDisplayTableOfContent = () => {
    console.log("toggleDisplayTableOfContent");
    this.displayTableOfContent = !this.displayTableOfContent;
  };

  constructor() {
    makeObservable(this, {
      breadcrumbs: observable,
      displayTableOfContent: observable,
      toggleDisplayTableOfContent: action
    });
  }
}

export default UiStore;
