import styled from "styled-components";

const PcHomePageStyled = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;

  .RsHomePageMobile {
    background-image: url(/images/sidebar-menu/side_menu_img.png);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-color: #e9e9e9;
    background-size: 100%;
  }
`;

export { PcHomePageStyled };
