import { action, computed, observable, reaction, runInAction, makeObservable } from "mobx";
import Api from "../services/Api";
import BaseStore from "./BaseStore";
import UiStore from "./UiStore";

type route = {
  path: string;
  text: string;
};

class TreesStore extends BaseStore {
  api: Api;
  uiStore: UiStore;

  contentLoading: boolean = false;
  loadingFetchTableOfContent: boolean = false;
  loadingFetchContentDetails: boolean = false;
  currentNodeToken: any = null;
  currentChapterId: any = null;
  isInit: boolean = false;

  constructor(api: Api, uiStore: UiStore) {
    super();

    makeObservable(this, {
      contentLoading: observable,
      loadingFetchTableOfContent: observable,
      loadingFetchContentDetails: observable,
      currentNodeToken: observable,
      currentChapterId: observable,
      isInit: observable,
      fetchNotes: action,
      currentChapterContent: computed,
      fetchChapter: action,
      setChapterId: action,
      mediaLinks: computed,
      treeLinks: computed,
      setCurrentNodeToken: action,
      currentNodeIsCategory: computed,
      currentNode: computed,
      getTree: action
    });

    this.api = api;
    this.uiStore = uiStore;
    this.init();

    reaction(
      () => this.currentNode,
      async (currentNode, reaction) =>
        (this.uiStore.breadcrumbs = this.createBreadcrumbs(this.currentNode))
    );

    reaction(
      () => this.currentChapterId,
      async (chapterId, reaction) => {
        if (this.currentTreeIsAudio) return;
        if (!chapterId) return;
        if (!this.currentNode.tableOfContent) {
          await this.fetchTableOfContent(this.currentNode.bookLinkId);
        }
        const parent = this.getParent(chapterId);
        // console.log({
        //   parent,
        //   chapterId,
        //   toc: JSON.parse(JSON.stringify(this.currentNode.tableOfContent)),
        // });
        if (chapterId && !this.currentNode.contents[parent.data]) {
          this.fetchChapter(parent.data);
        }
        if (!this.currentNode.notes) {
          this.fetchNotes(this.currentNode.bookLinkId);
        }
      }
    );
  }

  fetchNotes = async (bookLinkId: string) => {
    try {
      const footNotes = await (
        await this.api.fetchNotes(bookLinkId).promise
      ).json();

      const _footNotes: any = {};

      for (let [key, { id, text }] of Object.entries(footNotes) as any) {
        _footNotes[id] = text;
      }

      this.currentNode.footNotes = _footNotes;
      console.log(this.currentNode.footNotes, _footNotes);
    } catch (error) {
      console.error(error);
    }
  };

  init = async () => {
    const fetchSubjects = this.getTree(1479482716);
    const fetchBooks = this.getTree(1480662372);
    const fetchAudios = this.getTree(1466506535);
    const fetchLessons = this.getTree(2759928088);
    const fetchNewsInSite = this.getTree(942617850);
    const fetchRecommended = this.getTree(2753440010);

    await Promise.all([
      fetchSubjects,
      fetchBooks,
      fetchAudios,
      fetchLessons,
      fetchNewsInSite,
      fetchRecommended,
    ]);
    this.isInit = true;
  };

  get currentChapterContent() {
    if (!this.currentChapterId) return "";
    const parent: any = this.getParent(this.currentChapterId);
    // console.log({ parent });
    return this.currentNode.contents[parent.data];
  }

  get currentTreeIsAudio() {
    return this.currentTree.slug === "audio";
  }
  isMyChild = (nodeId: string, node: any) => {
    return this.findDeep(node.children || [], nodeId) !== undefined;
  };

  getContent = (nodeId: string) => {
    const parent: any = this.getParent(nodeId);
    return this.currentNode.contents[parent.data];
  };

  getParent = (nodeId: string) => {
    let current = this.findNode(nodeId, this.currentNode?.tableOfContent);
    // console.log(JSON.parse(JSON.stringify(this.currentNode?.tableOfContent)));

    if (!current) {
      console.warn(
        `Not find current node ${nodeId} in currentNode.tableOfContent`
      );
      console.warn("TOC as find in", this.currentNode?.tableOfContent);
      return "";
    }

    do {
      // console.log("find parent");
    } while (
      current.parent &&
      current.parent.data !== "toc_h0_0" &&
      (current = current.parent)
    );
    return current;
  };

  isFirstLevel = (nodeId: string) => {
    const node = this.currentNode.tableOfContent;
    return node.find((item: any) => item.data === nodeId) !== undefined;
  };

  fetchChapter = async (chapterId: string) => {
    try {
      if (!this.currentNode.footNotes) {
      }
      this.contentLoading = true;
      this.currentNode.contents[chapterId] = await (
        await this.api.fetchChapter(this.currentNode.bookLinkId, chapterId)
          .promise
      ).text();
    } catch (error) {
      console.error(error);
    }
    this.contentLoading = false;
  };

  setChapterId = async (chapterId: string) => {
    this.currentChapterId = chapterId;
  };

  getAudioDetails = async (id: string) => {
    if (!this.currentNode.details) {
      this.currentNode.details = {};
    }

    if (!this.currentNode?.details?.link) {
      const response = await this.api.fetchAudioDetails(id).promise;
      const media = await response.json();
      // this.currentTree.audio[id] = media;

      this.currentNode.details = { ...this.currentNode.details, ...media };
    }
    return this.currentNode.details;
  };

  fetchContentDetails = async (bookId: string) => {
    try {
      if (!bookId) return;
      this.loadingFetchContentDetails = true;
      const json = await (
        await this.api.fetchContentDetails(bookId).promise
      ).json();

      if (!this.currentNode.details) {
        this.currentNode.details = {};
      }

      this.currentNode.details = { ...this.currentNode.details, ...json };

      this.loadingFetchContentDetails = false;
      return;
    } catch (error) {
      console.error(error);
    }
  };

  fetchTableOfContent = async (bookId: number) => {
    try {
      if (!bookId) return;
      this.loadingFetchTableOfContent = true;
      const response = await this.api.fetchTableOfContent(bookId).promise;
      const responseJson = await response.json();

      this.currentNode.tableOfContent = this.fillWithParent(
        responseJson.treeStructure
      ).children;

      this.loadingFetchTableOfContent = false;
      return this.currentNode.tableOfContent;
    } catch (error) {
      console.error(error);
    }
  };

  get mediaLinks() {
    return this?.trees[this?.currentTreeName || 0].mediaLinks.find(
      (media: any) => media.tocId === this.currentNode.data
    );
  }

  get treeLinks() {
    return this?.trees[this?.currentTreeName || 0]?.treeLinks?.find(
      (media: any) => media.tocId === this.currentNode.data
    );
  }

  fillWithParent = (node: any, parent: any = null) => {
    node = {
      ...node,
      parent: parent ? Object.assign({}, parent) : null,
    };

    node.children &&
      (node.children = node.children.map((child: any) =>
        this.fillWithParent(child, node)
      ));

    return node;
  };

  setCurrentNodeToken = (currentNodeToken: string) => {
    this.currentNodeToken = currentNodeToken;
  };

  get currentNodeIsCategory() {
    return !this.isContentNode(this.currentNodeToken);
  }

  get currentNode() {
    const node = this.findNode(this.currentNodeToken);

    return node || this.currentTree;
  }

  createBreadcrumbs = (node: any): route[] => {
    if (!node) return [];
    let current = node;

    let items: route[] = [];
    do {
      const item = {
        path: current.route,
        text: current.text,
      };
      items.push(item);
    } while ((current = current.parent));

    items.push({
      path: `/${this.currentTree?.slug}`,
      text: this.currentTree?.name,
    });

    items.push({
      path: "/",
      text: "ראשי",
    });

    return items.reverse();
  };

  getTree = async (id: number) => {
    let treeSlug: any;
    try {
      let data = await (await this.api.getTree(id).promise).json();
      treeSlug = this.getTreeSlug(id);
      runInAction(() => {
        this.trees[treeSlug] = { ...this.trees[treeSlug], ...data };
        data = this.feelObject(data.treeStructure, null, `/${treeSlug}`, data);
        this.trees[treeSlug].children = data.children;
        this.trees[treeSlug].itemsCount = this.getChildrenCounter({
          children: this.trees[treeSlug].children,
        });
      });
    } catch (error) {
      console.warn(
        `Error when load tree, tree number  is ${id}, slug is ${treeSlug}`
      );
      console.log(error);
    }
  };
}

export default TreesStore;
