import styled from "styled-components";

const PcSearchStyled = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .max-width-form {
    max-width: 500px;
  }

  .max-width-form .InputSearch.InputWrapper {
    width: 100%;
  }
  .ant-pagination {
    direction: ltr;
  }

  .loading-mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff75;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
  }
`;

export { PcSearchStyled };
