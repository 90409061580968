import { Divider } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import SearchStore from "../../../../../../../stores/SearchStore";
import { ResultsStyled } from "./styled";
interface Props extends RouteComponentProps<any> {
  searchStore?: SearchStore;
}

class Results extends React.PureComponent<Props, any> {
  result(book: any, action: Array<string>) {
    return (
      <div className="flex paddingHor40 br-bottom br-blue textRight">
        <div className="flex1">
          <div className="f18 bold">{book.meta_data.name}</div>
          <div className="f18 ">{book.meta_data.subtitle}</div>
          <div className="f16 marginTop20">
            <TextTruncate
              line={3}
              element="div"
              truncateText="…"
              text={book.text}
            />
          </div>
          <div className="flex gray marginTop20">
            <div className="flex">
              <div className="marginLeft5">שם העורך:</div>
              <div className="">{book.meta_data.editor}</div>
            </div>

            <Divider className="marginTop5" type="vertical" />
            <div className="flex">
              <div className="marginLeft5">רמת עריכה:</div>
              <div className=""> {book.meta_data.reliabilityLevel}</div>
            </div>
            <Divider className="marginTop5" type="vertical" />
            <div className="flex">
              <div className="">{book.main_doc}</div>
            </div>
            <Divider className="marginTop5" type="vertical" />
            <div className="flex">
              <div className="">{book.meta_data.date}</div>
            </div>
          </div>

          <div
            className="f18 marginTop20 b-blueT textCenter white padding10   radius pointer "
            style={{ width: "200px" }}
          >
            עבור לעיון
          </div>
        </div>
        <div className="">
          <div className="f18 marginTop10 underLine blue2 pointer">
            <div className="padding20">
              <div className=" textCenter blueT ">
                <div className="flex justifyEnd marginBottom40">
                  {action !== undefined &&
                    action.map(function (item, i) {
                      return (
                        <div className="">
                          {item === "video" ? (
                            <img
                              alt="1"
                              className="marginLeft20"
                              width="30"
                              src={"/images/Book-type/video/video-icon-red.svg"}
                            />
                          ) : null}

                          {item === "audio" ? (
                            <img
                              alt="1"
                              className="marginLeft20"
                              width="30"
                              src={
                                "images/Book-type/Audio/audio-icon-purple.svg"
                              }
                            />
                          ) : null}

                          {item === "book" ? (
                            <img
                              alt="1"
                              className=""
                              width="30"
                              src={
                                "images/Book-type/text/text-icon-lightblue.svg"
                              }
                            />
                          ) : null}
                        </div>
                      );
                    })}
                </div>

                <div className="flex justifyEnd">
                  <div className="f15 br br-blue  padding5 radius margin5 ">
                    מעניין
                  </div>
                  <img
                    alt="1"
                    className="fleft"
                    width="15"
                    style={{ marginRight: "13px" }}
                    src="/images/tag.svg"
                  />
                </div>

                <div className="flex justifyEnd">
                  <div
                    className="f18 fw400 blueT  marginLeft20"
                    style={{ marginRight: "8px" }}
                  >
                    204
                  </div>
                  <img
                    alt="1"
                    className="fleft"
                    width="15"
                    style={{ marginRight: "13px" }}
                    src="/images/like/like-outline.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { results, query } = this.props.searchStore!;
    return (
      <ResultsStyled>
        <div className="blue padding40">
          <div className="f24  textRight ">
            תוצאות חיפוש עבור <b>[ {query}]</b>
          </div>
          <div className="f18">(3)</div>
          {results?.Result?.map((book: any, i: number) =>
            this.result(book, ["video", "audio", "book"])
          )}
        </div>
      </ResultsStyled>
    );
  }
}
export default withRouter(inject("searchStore")(observer(Results)));
