import React, { useState } from "react";
import {
  TableOfContentStyled,
  // TableOfContentTitleStyled
} from "./styled";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { categoryType } from "../pages/CategoryOrBook/CategoryOrBook";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
export type categoryType = "books" | "subjects" | "audio" | "lessons";

interface Item {
  isRegularLink?: boolean;
  text: string;
  data?: string;
  image?: string;
  children?: Item[];
  uuid: string;
  selected?: boolean;
}
interface Props {
  items: Item[];
  isHashTagLinks?: boolean;
  baseUrl?: string;
  activeNode?: string;
  isMyChild?: (collection: any, nodeId: string) => any;
  onClickTOCChild?: () => any;
}

const Item = ({
  item,
  level,
  isHashTagLinks,
  baseUrl,
  activeNode,
  isMyChild,
  onClickTOCChild,
}: any) => {
  const initDisplay =
    (isMyChild && isMyChild(activeNode, item)) ||
    activeNode === item.data ||
    false;
  const isLastLevel = typeof item.children === "undefined";

  const [display, setDisplay] = useState(initDisplay);
  return (
    <li>
      {!isHashTagLinks ? (
        <Link
          to={`${item.isRegularLink ? "" : "#"}${item.data}`}
          className={`${
            activeNode === item.data ? "selected 2 " : ""
          }cBlue list-item-${item.data}`}
        >
          <span
            style={{ paddingRight: level * 15 }}
            onClick={() => {
              if (onClickTOCChild) console.log({ onClickTOCChild });
              setDisplay(!display);
            }}
          >
            <span className={`${item.children ? "hasChildren" : ""}`}>
              {item.text}
            </span>
            {item.children && <span>({item.children.length})</span>}
          </span>
        </Link>
      ) : (
        <Link
          to={`${baseUrl}/${item.data}`}
          className={`${
            activeNode === item.data ? "selected 1 " : ""
          }cBlue  list-item-${item.data} 23423423423423`}
        >
          <span
            style={{ paddingRight: level * 15 }}
            onClick={() => {
              isLastLevel && onClickTOCChild();
              item.children && setDisplay(!display);
            }}
          >
            <span className={`${item.children ? "hasChildren" : ""} `}>
              {display
                ? item.children && <MinusOutlined />
                : item.children && <PlusOutlined />}
              <span className="paddingVer10">{item.text}</span>
            </span>
            {item.children && (
              <span className="children-counter">({item.children.length})</span>
            )}
          </span>
        </Link>
      )}

      {display && item.children && (
        <Wrapper
          onClickTOCChild={onClickTOCChild}
          children={item.children}
          level={level + 1}
          isHashTagLinks={isHashTagLinks}
          baseUrl={baseUrl}
          activeNode={activeNode}
          isMyChild={isMyChild}
        />
      )}
    </li>
  );
};

const Wrapper = ({
  children,
  level,
  isHashTagLinks,
  baseUrl,
  activeNode,
  isMyChild,
  onClickTOCChild,
}: any) => {
  return (
    <ul>
      {children.map((item: any, index: number) => (
        <Item
          onClickTOCChild={onClickTOCChild}
          item={item}
          level={level}
          key={`${item.uuid} - ${index}`}
          isHashTagLinks={isHashTagLinks}
          baseUrl={baseUrl}
          activeNode={activeNode}
          isMyChild={isMyChild}
        />
      ))}
    </ul>
  );
};

class TableOfContent extends React.PureComponent<Props, any> {
  doScrolling(elementY: number, duration: number, elementScroll: any = window) {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start: number;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);

      elementScroll.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }
  componentDidMount = () => {
    if (this.props.activeNode?.includes("/")) return;

    const elem: any = document.querySelector(
      `a.list-item-${this.props.activeNode}`
    );

    const tableOfContentElement = document.querySelector(
      `.RsTableOfContent .tableOfContent`
    );

    setTimeout(() => {
      console.log({ offsetTop: elem?.offsetTop - 180, elem });
      elem &&
        this.doScrolling(
          elem?.offsetTop - 180 ||
            // + 150
            0,
          500,
          tableOfContentElement
        );
    }, 300);
  };
  render() {
    return (
      <TableOfContentStyled className="tableOfContent ">
        {this.props.items && (
          <Wrapper
            onClickTOCChild={this.props.onClickTOCChild}
            children={this.props.items}
            level={1}
            isHashTagLinks={this.props.isHashTagLinks || false}
            baseUrl={this.props.baseUrl}
            activeNode={this.props.activeNode}
            isMyChild={this.props.isMyChild}
          />
        )}
      </TableOfContentStyled>
    );
  }
}

export default observer(TableOfContent);
